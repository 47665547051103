import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import BasePolicyModal from './BasePolicyModal';
import MarkdownLenderer from './MarkdownLenderer';

export default function PrivacyPolicyModal(props: { isShow: boolean; onClickCloseButton?: Function }) {
  const [markdownData, setMarkdownData] = useState('');

  useEffect(() => {
    changeMarkdownData('privacy20210701.md');
  }, []);

  async function changeMarkdownData(filename: string) {
    const response = await fetch(`/files/md/${filename}`);
    const data = await response.text();
    setMarkdownData(data);
  }

  return (
    <BasePolicyModal title="개인정보처리방침" isShow={props.isShow} onClickCloseButton={props.onClickCloseButton}>
      <div css={privacyPolicyModalCss} className="privacy-policy-container">
        <MarkdownLenderer markdownData={markdownData} />
      </div>
    </BasePolicyModal>
  );
}

const privacyPolicyModalCss = css`
  &.privacy-policy-container {
  }
`;
