const lang = {
  // tests
  hello: 'Good Morning',
  obj: {
    his: 'HURWEYUR2',
  },

  // real
  main: {
    subtitle: 'We create the best value by focusing on innovative services that are not available in the world.',
  },
  whoWeAreNav: {
    nav1: 'Overview and History',
    nav2: 'Core Values',
    nav3: 'Team',
    nav4: 'Donation',
  },
  whoWeAre: {
    section1: {
      sectionTitle: `
        It is contributing to <br /> Testing
        the popularization of domestic blockchain technology <br />
        and contactless smart technology.
      `,
      moreList: {
        history: `
        The largest in the country
        We are developing a blockchain system and developing a new blockchain-based contactless smart technology.
        `,
        aim: `
        In the global market
        As a leading D App company with the goal of the best, we provide the best service and fulfill our technology development and responsibility.
        `,
        system: `
        Through constant innovation
        Provides a complete system of more convenient and reliable development platforms.
        `,
      },
    },
    section2: {
      leftContent: `
        Since the end of 2016, <strong>Hans Biotech</strong> has been developing blockchain-based contactless smart technology to prepare a new cryptocurrency system.
        Most crypto markets are traded closely with vendors and consumers, and users have a strong interest in investing in cryptocurrencies, but they do not have much experience.
        The domestic blockchain market is unstable, but the revision of the Special Financial Law in March 2020 allowed us to create a transparent and legal market. From the beginning of 2019, CYBERTRONCHAIN has been the vision of Hans Biotech's smart payment market.
        Currently, the blockchain market is evolving rapidly, and Hans Biotech believes that blockchain will transform everyday services. There are many online platforms around the world, but users are not satisfied with the inconvenience and high fees they experience in everyday life. Hans Biotech provides better solutions to the integrated online and offline markets, and recognizes it as an opportunity to explore the world with a new payment system.
      `,
      leftContentMobile: `
        Since the end of 2016, <strong>Hans Biotech</strong> has been developing blockchain-based contactless smart technology to prepare a new cryptocurrency system.
        Most crypto markets are traded closely with vendors and consumers, and users have a strong interest in investing in cryptocurrencies, but they do not have much experience.
        The domestic blockchain market is unstable, but the revision of the Special Financial Law in March 2020 allowed us to create a transparent and legal market.
      `,
      roadmap: {
        t1: {
          date: 'July 2019',
          content: 'Personal wallet with identity verification (CTC fee)',
        },
        t2: {
          date: 'May 2020',
          content: 'Barry Barries P2P trading shopping mall (CTC fee)',
        },
        t3: {
          date: 'September 2020',
          content: 'Payment kiosk development (CTC fee)',
        },
        t4: {
          date: 'October 2020',
          content: 'European subsidiary established',
        },
        t5: {
          date: 'November 2020',
          content: `
            Coin IBT exchange opened <br />
            Smart mask kiosk development (CTC fee)
          `,
        },
        t6: {
          date: 'September 2021',
          content: `
          Coin ibt exchange closed
          `,
        },
        t7: {
          date: 'October 2021',
          content: `
          Signed an MOU with the Ministry of Industry, Technology and Resources of Tajikistan
          `,
        },
        t8: {
          date: 'October 2021',
          content: `
          Signed MOU for direct flights with Tajigistan Soman Airlines
          `,
        },
        t9: {
          date: 'May 2022',
          content: `
          Registration of import and export trade business
          `,
        },
        t10: {
          date: 'June 2022',
          content: `
          Tajikistan booth held at the World Import and Export Fair
          `,
        },
      },
      sectionRight: {
        hansWrap: 'Development of cryptocurrency system since 2016!',
        bioWrap: 'The vision of smart payment market!',
        techWrap: 'Bringing Blockchain to Life!',
      },
    },
    section3: {
      missionTitle: 'A lifestyle system that leads the improvement of quality of life by providing it to everyone',
      missionContent: `
        The infrastructure of Hans Biotech, a system for life that is provided to all to lead the improvement of the quality of life, is advancing the neutralization of domestic blockchain technology and virtual asset actual use. We are constantly striving to develop the ability to proactively cope with the rapidly changing future society and to produce valuable ideas and outputs with insight from a new perspective and flexible thinking.
      `,
      fourMission: {
        m1: {
          title: 'Innovation',
          content: 'We create new values and do our best to achieve our goals.',
        },
        m2: {
          title: 'Compliance with the principles',
          content: 'We adhere to the principles and value the values of conviction and trust.',
        },
        m3: {
          title: 'Flexibility',
          content: 'We observe environmental changes from various perspectives and lead new trends.',
        },
        m4: {
          title: 'The best in the world',
          content:
            'We are constantly striving to become a leader in the global market by providing the best experience and competitiveness.',
        },
      },
    },
    section4: {},
  },
  businessArea: {
    moveSite: 'Go to the website',
    text01: `
      Creating sustainable new services <br />
      in our lives through constant innovation
    `,
    coinIBT: {
      subtitle: 'Secure trading platform',
      desc: `
      Coin ibt has ceased operations after September 24, 2021 in compliance with the Anti-Money Laundering Act (Special Act) of Virtual Asset Operators.
      After that, we will meet you again in compliance with the scope of application of the Act in accordance with (Enforcement Decree of the Specific Financial Information Act). Thank you for loving Coin ibt.
      `,

      detail: {
        mainDesc: `
        Open after the ISMS certification authorization on September 24, 2021. Exit`,
        bottomTitle: 'Secure trading platform, COIN IBT Closed',
        bottomContent:
          'Coin IBT Exchange, opened in October 2020, is a platform that allows you to buy/sell cryptocurrencies such as Bitcoin and Ethereum. As a safe exchange used by people all over the world, the safety of customer assets is our top priority.',
        boxContents: {
          t1: {
            title: 'Trust',
            content: 'We build a financial environment that everyone can trust with transparent policies.',
          },
          t2: {
            title: 'Professional',
            content: 'The best specialists in Korea and abroad share their diverse experience and technologies.',
          },
          t3: {
            title: 'Innovative',
            content: 'We provide a stable investment of coins that you can trust and invest in.',
          },
        },
      },
    },
    ctcWallet: {
      subtitle: 'Secure Virtual asset wallet',
      desc: `
      Ctc Wallet started development in 2016 and
      It was introduced worldwide in July.
      CTC Wallet uses the Solidity language and WEP 3.0 technology.
      It was developed and equipped with the world's first personal real-name address.
      `,
      detail: {
        mainDesc:
          'CTC WALLET provides the most important virtual asset security or real name support and POS system. It is evolving to interoperability with high speed and scalability with blockchain technology using the technology form of D-app and smart contracts, and the technology of WEP 3.0 for smooth progress of payments, payments, and exchanges through non-face-to-face smart payment systems has been applied.',
        bottomTitle: 'Hans Biotech changed its management system to HANSBLOCK.ou, the initial developer of CTC wallet service.',
        bottomContent:
          'Thank you for loving Hans Biotech.',
        boxContents: {
          t1: {
            title: 'Multi-Security Protection',
            content: '',
          },
          t2: {
            title: 'Fast payments in app and web',
            content: '',
          },
          t3: {
            title: 'Easy data management',
            content: '',
          },
        },
      },
    },
    bbMarket: {
      subtitle: 'Secure trading platform',
      desc: `
        The CTC wallet is linked and you can trade immediately.
        To protect buyers, we provide a safe purchase function
        so that you can trade sellers' products with confidence.
      `,
      detail: {
        mainDesc:
          'BerryBerry Market is a P2P trading platform that provides (or receives) goods or services without having to face the person who sells through the platform and wants to buy them directly. It is a network of sellers and consumers who trade products and services through real-name authentication, and is close and usable in real life. It can settle taxes and process receipts with a transparent system, and supports the role of a "neighborhood flyer" by not only selling or purchasing, but also marketing and recruiting self-employed people.',
        bottomTitle: 'A platform for trading with E&#x02011TP3 and E&#x02011MC issued and managed by Coin IBT',
        bottomContent:
          'E-TP3 and E-MC are virtual currencies used only in Beriberi Market. These currencies can be exchanged into real coins such as TP3 and MC in the CTC wallet after the transaction is completed, and the coins that have been exchanged can also be exchanged for cash at IBT’s own coin exchange.',
        boxContents: {
          t1: {
            title: '',
            content: 'CTC wallet is linked to provide a safe purchase function so you can trade with confidence',
          },
          t2: {
            title: '',
            content:
              'If you register as a seller member, you can sell products with free commission and safety commission.',
          },
          t3: {
            title: '',
            content: 'Conversation between seller and buyer in real-time provides fast transaction',
          },
        },
      },
    },
  },
  newsRoom: {
    // sectionTitle: '한스바이오텍<span className="pc-only">에서 전하는</span> 소식과 이야기를 만나보세요.',
    sectionTitle: 'Read the news and stories from Hans Biotech.',
    mainNewsContent: `
      Hans Biotech Co., Ltd., which develops and operates a blockchain-based contactless smart payment system,
      is working with Hanmaeum Smart to develop an asset payment program using an unmanned kiosk (unmanned information terminal) program to commercialize it. 
      Unmanned kiosks are used in large commercial facilities such as shopping malls and various places in the community.
      Hans Biotech plans to develop a virtual asset payment program using an unmanned kiosk program and put it on the commercial market. 
      Hans Biotech provides and improves technology services for money transfers and virtual assets such as P2P-based distributed ledgers 
      and implements smart contract automatic payment system for advanced technology.
    `,
    directionShow: 'Learn more',
    newsList: {
      a1: {
        title:
          'Hans Biotech, a cryptocurrency that leads the core technology of the 4th industrial revolution in business',
        hashtags: {
          a1: 'Blockchain',
          a2: 'UnmannedKiosk',
          a3: 'Smartpaymentsystem',
          a4: 'HansBiotech',
          a5: 'OneHeartSmart',
        },
      },
      a2: {
        title: 'How to pay securely and smartly with virtual currency in the era of contactless',
        hashtags: {
          a1: 'Blockchain',
          a2: 'UnmannedKiosk',
          a3: 'Smartpaymentsystem',
          a4: 'HansBiotech',
          a5: 'OneHeartSmart',
        },
      },
    },
  },
  lastSection: {
    companyAddress: 'Woorim Lions Valley B-dong, 168 Gasan Digital 1-ro, Geumcheon-gu, Seoul',
  },
  footer: {
    sitemap: 'Sitemap',
    partnership: 'Partnership',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of service',
    companyRegistrationNumber: 'Company Registration Number',
  },
  common: {
    learnMore: 'Learn more',
    yourName: 'Name',
    emailAddress: 'Email address',
    pleaseEnter: 'Please enter the text',
    address: 'Adress',
    customerCenter: 'customerCenter',
    ceo: 'CEO',
    ceoName: 'Kim Rai',
    oneHeartSmart: 'OneHeartSmart',
  },
};

export default lang;
