import { colors, mixins, sizes } from '@/interfaces/constants';
import { getUuid } from '@/utils';
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function WhoWeAreSection3() {
  const { t, i18n } = useTranslation();
  const fourMission = useMemo(() => {
    return [
      {
        imgSrc: '/images/who-we-are-section/3/banner1.png',
        title: t('whoWeAre.section3.fourMission.m1.title'),
        content: t('whoWeAre.section3.fourMission.m1.content'),
      },
      {
        imgSrc: '/images/who-we-are-section/3/banner2.png',
        title: t('whoWeAre.section3.fourMission.m2.title'),
        content: t('whoWeAre.section3.fourMission.m2.content'),
      },
      {
        imgSrc: '/images/who-we-are-section/3/banner3.png',
        title: t('whoWeAre.section3.fourMission.m3.title'),
        content: t('whoWeAre.section3.fourMission.m3.content'),
      },
      {
        imgSrc: '/images/who-we-are-section/3/banner4.png',
        title: t('whoWeAre.section3.fourMission.m4.title'),
        content: t('whoWeAre.section3.fourMission.m4.content'),
      },
    ];
  }, [i18n.language]);

  return (
    <section css={whoWeAreSection3Css} className="section-page">
      <div className="who-we-are-section-3">
        <div className="section-inner">
          <div
            className="mission"
            style={{
              paddingRight: i18n.language === 'en' ? '15%' : '',
            }}
          >
            {/* <div>
              width: {window.innerWidth} <br />
              height: {window.innerHeight}
            </div> */}
            <h1 className="mission-title">Mission</h1>
            <div className="mission-content">
              <strong>{t('whoWeAre.section3.missionTitle')}</strong>
              <span>{t('whoWeAre.section3.missionContent')}</span>
            </div>
          </div>
          <div className="four-mission">
            {fourMission.map(({ imgSrc, title, content }) => (
              <div className="mission-item" key={JSON.stringify({ imgSrc, title, content })}>
                <img src={process.env.PUBLIC_URL + imgSrc} alt={title} className="mission-item-img" />
                <div>
                  <h2 className="mission-item-title">{title}</h2>
                  <p className="mission-item-content">{content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

const whoWeAreSection3Css = css`
  height: 100%;
  background-color: ${colors.white};
  padding-top: ${sizes.whoWeAreNavHeight}px;
  .who-we-are-section-3 {
    padding-top: ${sizes.whoWeAreNavHeight}px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${mixins.setMaxWidth}
    height: 100%;
    .section-inner {
      display: flex;
      width: ${sizes.whoWeAreWidth}px;
      margin: 0 auto;
      flex-direction: column;

      .mission {
        display: flex;
        margin-bottom: 38px;
        padding-right: 45%;
        padding-left: 5%;

        .mission-title {
          margin-right: 4.2rem;
          font-size: 1.8rem;
          font-family: 'Poppins';
        }
        .mission-content {
          font-size: 1.6rem;
          line-height: 2.4rem;

          strong {
            margin-bottom: 1.8rem;
            display: block;
          }
        }
      }
      .four-mission {
        display: flex;
        flex-wrap: wrap;
        padding-left: 9%;

        .mission-item {
          font-size: 1.6rem;
          /* width: calc(50% - 30.5px); */
          width: 50%;
          &:nth-of-type(odd) {
            /* margin-right: 61px; */
          }

          &:nth-of-type(1),
          &:nth-of-type(2) {
            margin-bottom: 41px;
          }
          display: flex;
          flex-direction: column;

          .mission-item-img {
            /* width: 528px;
            height: 221px; */
            width: 82%;
            margin-bottom: 11px;
          }
          .mission-item-title {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  @media (max-width: ${sizes.maxWidth}px) {
    .who-we-are-section-3 {
      align-items: flex-start;
      .section-inner {
        padding: 25px 10px;
        .mission {
          padding: 0;
          margin: 0;
          flex-direction: column;
          margin-bottom: 40px;
          .mission-title {
            font-size: 3.8rem;
          }
          .mission-content {
            strong {
              font-size: 1.9rem;
            }
            > span {
              display: none;
            }
          }
        }
        .four-mission {
          /* padding: 25px 10px; */
          padding: 0 10px;
          flex-wrap: nowrap;
          flex-direction: column;
          .mission-item {
            width: 100%;
            flex-direction: row;
            align-items: center;

            &:nth-of-type(odd) {
              margin-bottom: 18px;
            }

            &:nth-of-type(1),
            &:nth-of-type(2) {
              margin-bottom: 18px;
            }

            .mission-item-img {
              width: 45%;
              height: initial;
              margin-right: 10px;
              margin-bottom: 0;
            }
            .mission-item-title {
              margin-bottom: 6px;
            }
            .mission-item-content {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
`;

export default WhoWeAreSection3;
