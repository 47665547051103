import TextWithBrTag from '@/components/common/TextWithBrTag';
import TouchPanel from '@/components/TouchPanel';
import { colors, mixins, sizes } from '@/interfaces/constants';
import { useRootState } from '@/store/RootStore';
import { onMobileSize } from '@/utils';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function WhoWeAreSection1() {
  const { fullpageApi } = useRootState();
  const { t, i18n } = useTranslation();
  let scrollEndCount = 0;
  const moreList = [
    {
      bgSrc: '/images/who-we-are-section/1/history.png',
      title: 'HISTORY',
      content: t('whoWeAre.section1.moreList.history'),
    },
    {
      bgSrc: '/images/who-we-are-section/1/aim.png',
      title: 'AIM',
      content: t('whoWeAre.section1.moreList.aim'),
    },
    {
      bgSrc: '/images/who-we-are-section/1/system.png',
      title: 'SYSTEM',
      content: t('whoWeAre.section1.moreList.system'),
    },
  ];

  const [titleText, setTitleText] = useState('HANSBIOTECH');

  useEffect(() => {
    const releseHandler = onMobileSize(
      () => {
        setTitleText('Who We Are');
      },
      () => {
        setTitleText('HANSBIOTECH');
      },
    );

    return () => {
      releseHandler();
    };
  });

  return (
    <section css={whoWeAreSection1Css} className="section-page">
      <div className="who-we-are-section-1">
        <div className="section-inner">
          <div className="site-logo-text">{titleText}</div>
          <div
            className="section-title"
            dangerouslySetInnerHTML={{ __html: t('whoWeAre.section1.sectionTitle') }}
          ></div>
          <div
            className="more-button"
            onClick={() => {
              fullpageApi?.moveSlideRight();
            }}
          >
            <span>{t('common.learnMore')}</span>
            <img src={process.env.PUBLIC_URL + '/images/common/arrow-right-white.png'} alt="" />
          </div>

          <TouchPanel
            style={{ flex: 1 }}
            touchHandler={(touchDirection) => {
              if (touchDirection === 'top') {
                console.log('top');
                fullpageApi?.moveSectionUp();
              } else if (touchDirection === 'bottom') {
                fullpageApi?.moveSectionDown();
              }
            }}
          >
            <div
              className="more-list-wrap"
              // onTouchStart={(e) => e.stopPropagation()}
              // onTouchEnd={(e) => e.stopPropagation()}
              // onTouchMove={(e) => e.stopPropagation()}
              onTouchMoveCapture={(e) => {
                e.stopPropagation();
                console.log(e);
                if (!(e.target instanceof HTMLElement)) return;

                let parent = e.target;
                while (true) {
                  // if (parent.classList.contains('more-list')) {
                  if (parent.classList.contains('more-list-wrap')) {
                    break;
                  }
                  if (!parent.parentElement) {
                    break;
                  }
                  parent = parent.parentElement;
                }
                console.log('parent? ', parent.scrollWidth);
                console.log('parent? ', parent.scrollLeft + parent.clientWidth);
                const isScrollEnd = parent.scrollWidth <= parent.scrollLeft + parent.clientWidth;

                if (isScrollEnd) {
                  // e.stopPropagation();
                  if (3 <= scrollEndCount) {
                    fullpageApi?.moveSlideRight();
                    scrollEndCount = 0;
                  }
                  scrollEndCount++;
                } else {
                  scrollEndCount = 0;
                }
              }}
              // onTouchStartCapture={(e) => e.stopPropagation()}
              // onTouchEndCapture={(e) => e.stopPropagation()}
            >
              <div className="more-list">
                {moreList.map((item) => (
                  <div
                    className="more-list-item"
                    css={{
                      backgroundImage: `url("${process.env.PUBLIC_URL}${item.bgSrc}")`,
                    }}
                    key={JSON.stringify(item)}
                  >
                    {/* <div>{key}</div> */}
                    <div className="bg-opacity"></div>
                    <div className="item-title">{item.title}</div>
                    <div
                      className="item-content"
                      style={{
                        height: i18n.language === 'en' ? '59%' : '',
                      }}
                    >
                      <span className="pc-only">
                        <TextWithBrTag text={item.content} />
                      </span>
                      <span className="mobile-only">{item.content}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </TouchPanel>
        </div>
      </div>
    </section>
  );
}

const whoWeAreSection1Css = css`
  height: 100%;
  background-color: ${colors.whites['002']};
  .who-we-are-section-1 {
    padding: 60px 0;
    /* padding-bottom: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;

    ${mixins.setMaxWidth}
    height: 100%;

    .section-inner {
      position: relative;
      /* overflow: auto; */
      .site-logo-text {
        color: #0098e2;
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 1.8rem;
        margin-bottom: 4rem;
      }
      .section-title {
        color: ${colors.black};
        font-weight: 700;
        font-size: 3rem;
        line-height: 4.8rem;
        margin-bottom: 3.4rem;
      }
      .more-button {
        color: ${colors.white};
        font-size: 1.6rem;
        font-weight: 700;
        width: 164px;
        height: 50px;
        display: flex;
        align-items: center;
        padding-left: 17px;
        padding-right: 17px;
        background-color: ${colors.black};
        margin-bottom: 9.6rem;
        cursor: pointer;
        justify-content: space-between;

        > img {
          width: 30px;
        }
      }
      .more-list {
        display: flex;
        overflow: auto;

        .more-list-item {
          color: ${colors.white};
          padding: 0 50px 40px;
          border-radius: 20px;
          /* IE11 때문에 flex 1 대신에 width로 주었다. */
          /* flex: 1; */
          width: 33.33%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 400px;
          position: relative;
          background-repeat: no-repeat;
          background-size: cover;
          max-width: 300px;
          letter-spacing: 0.1rem;

          .bg-opacity {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            top: 0;
            left: 0;
            border-radius: 20px;
          }

          &:not(:last-of-type) {
            margin-right: 48px;
          }

          .item-title {
            text-transform: uppercase;
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 3.3rem;
            margin-bottom: 15px;
            position: relative;
          }
          .item-content {
            font-size: 1.5rem;
            line-height: 2;
            position: relative;
            height: 42%;

            .mobile-only {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: ${sizes.maxWidth}px) {
    .who-we-are-section-1 {
      justify-content: flex-start;
      padding-left: 40px;
      padding-bottom: 60px;
      padding-top: 20px;
      .section-inner {
        height: 100%;
        display: flex;
        flex-direction: column;

        .site-logo-text {
          font-size: 4rem;
        }
        .section-title {
        }
        .more-button {
          font-size: 2.6rem;
          margin-bottom: 2.6rem;
        }
        .more-list-wrap {
          flex: 1;
          overflow-x: auto;
          height: 100%;
          .more-list {
            /* display: none; */
            width: 200%;
            height: 100%;
            align-items: flex-end;

            .more-list-item {
              height: 100%;
              max-height: 320px;
              padding: 0;
              padding: 2rem;
              width: initial;
              flex: 1;
              margin-right: 40px;
              max-width: 230px;
              .bg-opacity {
              }

              &:not(:last-of-type) {
                margin-right: 16px;
              }

              .item-title {
              }
              .item-content {
                font-size: 2rem;
                .pc-only {
                  display: none;
                }
                .mobile-only {
                  display: initial;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default WhoWeAreSection1;
