import TextWithBrTag from '@/components/common/TextWithBrTag';
import { colors, mixins, sizes } from '@/interfaces/constants';
import { onMobileSize } from '@/utils';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function WhoWeAreSection2() {
  const { t } = useTranslation();
  const leftContent = t('whoWeAre.section2.leftContent');
  const leftContentMobile = t('whoWeAre.section2.leftContentMobile');

  return (
    <section css={whoWeAreSection2Css} className="section-page">
      <div className="who-we-are-section-2-wrap">
        <div className="who-we-are-section-2">
          <div className="section-inner">
            <div className="section-left">
              {/* <div>window.innerWidth: {window.innerWidth}</div> */}
              <h1 className="left-title">ABOUT HANSBIOTECH</h1>
              <p className="left-content">
                <TextWithBrTag text={leftContent} withDangerouslySetInnerHTML className="pc-only" />
                <TextWithBrTag text={leftContentMobile} withDangerouslySetInnerHTML className="mobile-only" />
              </p>
              <div className="roadmap-list">
                <div className="roadmap-item">
                  <span className="roadmap-date">- {t('whoWeAre.section2.roadmap.t1.date')}</span>
                  <span className="roadmap-content">{t('whoWeAre.section2.roadmap.t1.content')}</span>
                </div>
                <div className="roadmap-item">
                  <span className="roadmap-date">- {t('whoWeAre.section2.roadmap.t2.date')}</span>
                  <span className="roadmap-content">{t('whoWeAre.section2.roadmap.t2.content')}</span>
                </div>
                <div className="roadmap-item">
                  <span className="roadmap-date">- {t('whoWeAre.section2.roadmap.t3.date')}</span>
                  <span className="roadmap-content">{t('whoWeAre.section2.roadmap.t3.content')}</span>
                </div>
                <div className="roadmap-item">
                  <span className="roadmap-date">- {t('whoWeAre.section2.roadmap.t4.date')}</span>
                  <span className="roadmap-content">{t('whoWeAre.section2.roadmap.t4.content')}</span>
                </div>
                <div className="roadmap-item">
                  <span className="roadmap-date">- {t('whoWeAre.section2.roadmap.t5.date')}</span>
                  <span className="roadmap-content" dangerouslySetInnerHTML={{ __html: t('whoWeAre.section2.roadmap.t5.content') }}>
                  </span>
                </div>
                <div className="roadmap-item">
                  <span className="roadmap-date">- {t('whoWeAre.section2.roadmap.t6.date')}</span>
                  <span className="roadmap-content" dangerouslySetInnerHTML={{ __html: t('whoWeAre.section2.roadmap.t6.content') }}>
                  </span>
                </div>
                <div className="roadmap-item">
                  <span className="roadmap-date">- {t('whoWeAre.section2.roadmap.t7.date')}</span>
                  <span className="roadmap-content" dangerouslySetInnerHTML={{ __html: t('whoWeAre.section2.roadmap.t7.content') }}>
                  </span>
                </div>
                <div className="roadmap-item">
                  <span className="roadmap-date">- {t('whoWeAre.section2.roadmap.t8.date')}</span>
                  <span className="roadmap-content" dangerouslySetInnerHTML={{ __html: t('whoWeAre.section2.roadmap.t8.content') }}>
                  </span>
                </div>
                <div className="roadmap-item">
                  <span className="roadmap-date">- {t('whoWeAre.section2.roadmap.t9.date')}</span>
                  <span className="roadmap-content" dangerouslySetInnerHTML={{ __html: t('whoWeAre.section2.roadmap.t9.content') }}>
                  </span>
                </div>
                <div className="roadmap-item">
                  <span className="roadmap-date">- {t('whoWeAre.section2.roadmap.t10.date')}</span>
                  <span className="roadmap-content" dangerouslySetInnerHTML={{ __html: t('whoWeAre.section2.roadmap.t10.content') }}>
                  </span>
                </div>

              </div>
              <div className="left-mobile-bottom">
                <div className="text-wrap-box">
                  <span>HANSBIO</span>
                  <div
                    className="hans-wrap"
                    css={{
                      backgroundImage: `url('${process.env.PUBLIC_URL}/images/who-we-are-section/2/hans-wrap.png')`,
                    }}
                  ></div>
                </div>
                <div className="text-wrap-box">
                  <span>TECH</span>
                  <div
                    className="tech-wrap"
                    css={{
                      backgroundImage: `url('${process.env.PUBLIC_URL}/images/who-we-are-section/2/bio-wrap.png')`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="section-right">
              <div className="text-wrap-box">
                <span>HANS</span>
                <div
                  className="hans-wrap"
                  css={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/images/who-we-are-section/2/hans-wrap.png')`,
                  }}
                ></div>
                <div className="text-deco hans-deco">{t('whoWeAre.section2.sectionRight.hansWrap')}</div>
              </div>
              <div className="text-wrap-box">
                <span>BIO</span>
                <div
                  className="bio-wrap"
                  css={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/images/who-we-are-section/2/bio-wrap.png')`,
                  }}
                ></div>
                <div className="text-deco bio-deco">{t('whoWeAre.section2.sectionRight.bioWrap')}</div>
              </div>
              <div className="text-wrap-box">
                <span>TECH</span>
                <div
                  className="tech-wrap"
                  css={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/images/who-we-are-section/2/tech-wrap.png')`,
                  }}
                ></div>
                <div className="text-deco tech-deco">{t('whoWeAre.section2.sectionRight.techWrap')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const whoWeAreSection2Css = css`
  height: 100%;
  background-color: ${colors.whites['002']};
  .who-we-are-section-2-wrap {
    padding-top: ${sizes.whoWeAreNavHeight}px;
    height: 100%;

    .who-we-are-section-2 {
      padding: 46px 0;
      /* padding-bottom: 100px; */
      display: flex;
      justify-content: flex-start;
      align-items: center;
      ${mixins.setMaxWidth}
      height: 100%;
      .section-inner {
        display: flex;
        width: ${sizes.whoWeAreWidth}px;
        margin: 0 auto;

        .section-left {
          width: 50%;
          margin-right: 37px;

          .left-title {
            font-family: 'Poppins';
            font-size: 1.8rem;
            margin-bottom: 5.1rem;
          }
          .left-content {
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin-bottom: 86px;
          }
          .roadmap-list {
            .roadmap-item {
              font-size: 1.6rem;
              line-height: 1.8;
              display: flex;
              .roadmap-date {
                font-weight: 700;
                width: 26%;
              }
              .roadmap-content {
                flex: 1;
              }
            }
          }

          .left-mobile-bottom {
            display: none;
          }
        }
        .section-right {
          width: 50%;
          /* position: relative; */
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .text-wrap-box {
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 16rem;
            color: ${colors.white};
            position: relative;
            padding: 3rem 2.7rem;
            line-height: 1;
            background-color: #233041;
            &:not(:last-of-type) {
              margin-bottom: 28px;
            }
            span {
              position: relative;
              z-index: 1;
            }

            .hans-wrap {
              position: absolute;
              background-repeat: no-repeat;
              top: 0;
              left: 0;
              width: 143%;
              height: 100%;

              background-position: 58%;
              background-size: cover;
            }
            .bio-wrap {
              position: absolute;
              background-repeat: no-repeat;
              bottom: 3rem;
              left: 2.7rem;
              width: 371px;
              height: 22px;
            }
            .tech-wrap {
              position: absolute;
              background-repeat: no-repeat;
              width: 130px;
              height: 78px;
              top: 0%;
              left: 86%;
              z-index: 2;
            }

            .text-deco {
              position: absolute;
              font-family: 'TvN';
              font-size: 2.2rem;
              font-weight: 700;
              color: #363636;
            }
            .hans-deco {
              left: calc(100% + 13px);
              top: calc(50% - 1.1rem);
              width: 100%;
            }
            .bio-deco {
              left: 123%;
              top: 73%;
              width: 100%;
            }
            .tech-deco {
              left: 115%;
              top: -5%;
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (max-width: ${sizes.maxWidth}px) {
    .who-we-are-section-2-wrap {
      background-color: #fff;
      .who-we-are-section-2 {
        padding: 20px 14px;
        align-items: flex-start;
        .section-inner {
          width: initial;
          .section-left {
            width: 100%;
            margin: 0;
            .left-title {
              font-size: 3.2rem;
              margin-bottom: 1rem;
            }
            .left-content {
              line-height: 1.5;
              font-size: 2rem;
              margin-bottom: 11px;
            }
            .roadmap-list {
              margin-bottom: 4rem;
              .roadmap-item {
                font-size: 2rem;
                .roadmap-date {
                  /* width: 26%; */
                  width: 118px;
                }
              }
            }
            .left-mobile-bottom {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .text-wrap-box {
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 6.2rem;
                color: ${colors.white};
                position: relative;
                padding: 3rem 2.7rem;
                line-height: 1;
                background-color: #233041;
                display: block;
                overflow-x: hidden;

                &:not(:last-of-type) {
                  margin-bottom: 2px;
                }

                .hans-wrap {
                  position: absolute;
                  background-repeat: no-repeat;
                  top: 0;
                  left: 0;
                  width: 145%;
                  height: 100%;

                  background-position: left;
                  /* background-size: cover; */
                  background-size: 98% 100%;
                }

                .tech-wrap {
                  position: absolute;
                  background-repeat: no-repeat;
                  bottom: 1rem;
                  left: 2rem;
                  width: 80%;
                  height: 15px;
                  background-size: 150%;
                }
              }
            }
          }
          .section-right {
            display: none;
            .text-wrap-box {
              &:not(:last-of-type) {
              }
              span {
              }

              .hans-wrap {
              }
              .bio-wrap {
              }
              .tech-wrap {
              }

              .text-deco {
              }
              .hans-deco {
              }
              .bio-deco {
              }
              .tech-deco {
              }
            }
          }
        }
      }
    }
  }
`;

export default WhoWeAreSection2;
