import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import BasePolicyModal from './BasePolicyModal';
import MarkdownLenderer from './MarkdownLenderer';

export default function TermsModal(props: { isShow: boolean; onClickCloseButton?: Function }) {
  const [markdownData, setMarkdownData] = useState('');

  useEffect(() => {
    changeMarkdownData('termsOfService.md');
  }, []);

  async function changeMarkdownData(filename: string) {
    const response = await fetch(`/files/md/${filename}`);
    const data = await response.text();
    setMarkdownData(data);
  }

  return (
    <BasePolicyModal title="이용약관" isShow={props.isShow} onClickCloseButton={props.onClickCloseButton}>
      <div css={termsModalCss} className="terms-container">
        <MarkdownLenderer markdownData={markdownData} />
      </div>
    </BasePolicyModal>
  );
}

const termsModalCss = css`
  &.terms-container {
  }
`;
