import React from 'react';
import { useEffect } from 'react';

function MainBgVideo() {
  useEffect(() => {
    console.log('video moutn');
  });

  return (
    /**
     * @dev
     * data-autoplay 를 추가해줘야 다른 section 다녀와도 자동 재생이 된다.
     * https://alvarotrigo.com/fullPage/examples/videoBackground.html
     */
    <video className="bg-video" muted autoPlay loop playsInline data-autoplay>
      <source
        src={process.env.PUBLIC_URL + '/images/main-section/bg-3.mp4'}
        type="video/mp4"
      />
    </video>
  );
}

export default React.memo(MainBgVideo);
// export default MainBgVideo;
