import { sizes } from '@/interfaces/constants';
import cs from 'classnames';
import { css } from '@emotion/react';
import { useRootState } from '@/store/RootStore';
import { useEffect, useState } from 'react';
import { getUuid } from '@/utils';
import { useTranslation } from 'react-i18next';

type WhoWeAreNavProps = {
  navOpen: boolean;
};
function WhoWeAreNav({ navOpen }: WhoWeAreNavProps) {
  const { fullpageState, fullpageApi } = useRootState();
  const {t, i18n} = useTranslation();
  const navTexts = [
    t('whoWeAreNav.nav1'),
    t('whoWeAreNav.nav2'),
    t('whoWeAreNav.nav3'),
    t('whoWeAreNav.nav4')
  ];
  const [navItems, setNavItems] = useState([
    {
      text: navTexts[0],
      active: false,
    },
    {
      text: navTexts[1],
      active: false,
    },
    {
      text: navTexts[2],
      active: false,
    },
    {
      text: navTexts[3],
      active: false,
    },
  ]);

  useEffect(() => {
    setNavItems(
      navItems.map((item, index) => ({
        ...item,
        text: navTexts[index],
      }))
    )
  }, [i18n.language]);


  useEffect(() => {
    // console.log('fullpageState change? ', fullpageState);
    const slideIndex = fullpageState?.slideIndex?.index as number;
    setNavState(slideIndex - 1);
  }, [fullpageState?.slideIndex?.index]);

  function onClickNavItem(slideIndex: number) {
    fullpageApi?.moveTo(2, slideIndex + 1);
  }

  function setNavState(slideIndex: number) {
    setNavItems(
      navItems.map((item, itemIndex) => {
        item.active = false;
        if (slideIndex === itemIndex) {
          item.active = true;
        }
        return item;
      }),
    );
  }

  return (
    <nav css={whoWeAreNavCss}>
      <div
        className={cs({
          'who-we-are-nav': true,
          open: navOpen,
        })}
      >
        <div className="who-we-are-nav-inner">
          {navItems.map(({ text, active }, index) => (
            <div
              className={cs({
                'nav-item': true,
                active,
              })}
              key={getUuid()}
              onClick={() => onClickNavItem(index)}
            >
              {text}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
}

const whoWeAreNavCss = css`
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  z-index: 90;
  .who-we-are-nav {
    height: 0;
    background-color: #233041;
    overflow: hidden;
    transition: height 1s;

    &.open {
      height: ${sizes.whoWeAreNavHeight}px;
    }

    .who-we-are-nav-inner {
      max-width: ${sizes.whoWeAreWidth}px;
      margin: 0 auto;
      height: 100%;

      display: flex;
      align-items: center;
      .nav-item {
        width: 100px;
        font-size: 1.5rem;
        color: #f9f9f9;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
        margin-top: 6px;
        padding-bottom: 6px;
        border-bottom-width: 2px;
        border-color: transparent;

        &:not(:last-of-type) {
          margin-right: 45px;
        }

        &.active {
          color: #55c3e9;
          border-color: #55c3e9;
        }
      }
    }
  }

  @media (max-width: ${sizes.maxWidth}px) {
    .who-we-are-nav {
      &.open {
      }

      .who-we-are-nav-inner {
        align-items: stretch;
        .nav-item {
          flex: 1;
          margin: 0;
          text-align: center;
          display: flex;
          justify-content: center;
          /* align-items: flex-end; */
          align-items: center;
          padding-bottom: 2rem;
          font-size: 2.3rem;
          border-bottom-width: 4px;
          &:not(:last-of-type) {
            margin: 0;
          }

          &.active {
          }
        }
      }
    }
  }
`;

export default WhoWeAreNav;
