import MainBgVideo from '@/components/MainBgVideo';
import { colors, mixins, sizes } from '@/interfaces/constants';
import { css } from '@emotion/react';
import { fullpageApi } from '@fullpage/react-fullpage';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type MainSectionProps = {
  fullpageApi: fullpageApi;
};
function MainSection({ fullpageApi }: MainSectionProps) {
  const {t} = useTranslation();

  return (
    <div css={mainSectionCss} className="section-page">
      <MainBgVideo />
      <div className="main-section-container">
        <div className="front-text-area">
          <h1 translate="no" className="title">
            GLOBAL LEADING <br />
            HANSBIOTECH
          </h1>
          <h2 className="subtitle">
            {t('main.subtitle')}
          </h2>
          <img
            src={
              process.env.PUBLIC_URL + '/images/main-section/enter-arrow.png'
            }
            alt="enter"
            className="enter-arrow"
            onClick={() => {
              fullpageApi.moveTo(2, 1);
            }}
          />
        </div>
      </div>
    </div>
  );
}

const mainSectionCss = css`
  width: 100%;
  height: 100%;
  background-image: url('${process.env
    .PUBLIC_URL}/images/main-section/bg-temp-low.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: rgba(0, 0, 0, 0.5); */
  position: relative;
  background-position: center center;

  .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center center;

    /* for IE11 and IE10 */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      transform: translateX(-50%) translateY(-50%);
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
    }
  }

  .main-section-container {
    /* ${mixins.setWidth} */
    position: relative;
    height: 100%;

    .front-text-area {
      position: absolute;
      top: 30%;
      left: 15%;
      color: ${colors.whites['001']};

      .title {
        font-size: 6rem;
        font-family: 'Poppins';
        margin-bottom: 4.5rem;
      }
      .subtitle {
        font-size: 2.2rem;
        margin-bottom: 4.8rem;
      }

      .enter-arrow {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: ${sizes.maxWidth}px) {
    .bg-video {
      /* for IE11 and IE10 */
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      }
    }

    .main-section-container {
      .front-text-area {
        padding-right: 100px;
        .title {
        }
        .subtitle {
        }

        .enter-arrow {
          width: 33px;
          height: 33px;
          transform: rotate(90deg);
        }
      }
    }
  }
`;

export default MainSection;
