import { useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import ReactFullpage, {
  fullpageApi as FullpageApi,
} from '@fullpage/react-fullpage';
import Nav from './components/Nav';
import MainSection from './pages/MainSection';
import { colors, sizes } from './interfaces/constants';
import WhoWeAreSection1 from './pages/WhoWeAreSection/WhoWeAreSection1';
import WhoWeAreSection2 from './pages/WhoWeAreSection/WhoWeAreSection2';
import WhoWeAreSection3 from './pages/WhoWeAreSection/WhoWeAreSection3';
import WhoWeAreSection4 from './pages/WhoWeAreSection/WhoWeAreSection4';
import WhoWeAreSection5 from './pages/WhoWeAreSection/WhoWeAreSection5';
import NewsRoom from './pages/NewsRoom';
import LastSection from './pages/LastSection';
import { useRootDispatch, useRootState } from './store/RootStore';
import WhoWeAreNav from './components/WhoWeAreNav';
import BusinessAreaSection from './pages/BusinessAreaSection/BusinessAreaSection';
// import { fullpageApi as FullpageApi } from '@fullpage/react-fullpage';

function App() {
  const [isTopSection, setIsTopSection] = useState(true);
  const [whoWeAreNavOpen, setWhoWeAreNavOpen] = useState(false);
  const fullpageRef = useRef<any>(null);

  function onLeave(origin: any, destination: any, direction: any) {
    // console.log('onLeave', { origin, destination, direction });

    const noScrollSlide = origin.item.querySelector(
      '.slide.active.fp-noscroll',
    );
    if (noScrollSlide) {
      // return false;
    }

    checkIsTopSection(destination);
  }

  function afterLoad(origin: any, destination: any, direction: any) {
    // console.log('afterLoad', origin, destination);
  }

  function onSlideLeave(
    section: any,
    origin: any,
    destination: any,
    direction: any,
  ) {
    checkWhoWeAreNav(section, destination);
  }

  function checkIsTopSection(destination: any) {
    // console.log(destination.index);
    if (destination.index === 0) {
      setIsTopSection(true);
      return;
    }
    setIsTopSection(false);
  }

  function checkWhoWeAreNav(section: any, destination: any) {
    if (section.index === 1 && destination.index !== 0) {
      setWhoWeAreNavOpen(true);
      return;
    }
    setWhoWeAreNavOpen(false);
  }

  return (
    <div css={appCss}>
      <Nav isTopSection={isTopSection} />
      <ReactFullpage
        licenseKey="C75E1A71-2A7F4F5B-9C2C85C3-C8C34BF6"
        scrollOverflow={true}
        onLeave={onLeave}
        afterLoad={afterLoad}
        onSlideLeave={onSlideLeave}
        verticalCentered={false}
        controlArrowColor={'#233041'}
        loopHorizontal={false}
        resetSliders={true}
        ref={fullpageRef}
        // touchSensitivity={20}
        render={({ state, fullpageApi }) => {
          return (
            <RenderReactFullpageWrapper
              state={state}
              fullpageApi={fullpageApi}
              whoWeAreNavOpen={whoWeAreNavOpen}
            />
          );
        }}
      />
    </div>
  );
}
type RenderReactFullpageWrapperProps = {
  state: any;
  fullpageApi: FullpageApi;
  whoWeAreNavOpen: boolean;
};
function RenderReactFullpageWrapper({
  state,
  fullpageApi,
  whoWeAreNavOpen,
}: RenderReactFullpageWrapperProps) {
  const rootDispatch = useRootDispatch();
  useEffect(() => {
    rootDispatch({ type: 'setFullpageApi', fullpageApi });
    rootDispatch({ type: 'setFullpageState', fullpageState: state });
  });

  useEffect(() => {
    // Who We Are Slide일 때 마우스 스크롤 막기
    if (
      state?.section?.index === 1 &&
      1 <= state?.slideIndex.index &&
      state.lastEvent === 'onSlideLeave'
    ) {
      // fullpageApi?.setMouseWheelScrolling(false);
      fullpageApi?.setAllowScrolling(false, 'up, down');
      return;
    } else {
      // fullpageApi?.setMouseWheelScrolling(true);
      fullpageApi?.setAllowScrolling(true);
    }
  }, [state]);

  return (
    <ReactFullpage.Wrapper>
      <div className="section">
        <MainSection fullpageApi={fullpageApi} />
      </div>
      <div className="section">
        <WhoWeAreNav navOpen={whoWeAreNavOpen} />
        <div className="slide">
          <WhoWeAreSection1 />
        </div>
        <div className="slide fp-noscroll open-nav">
          <WhoWeAreSection2 />
        </div>
        <div className="slide fp-noscroll open-nav">
          <WhoWeAreSection3 />
        </div>
        <div className="slide fp-noscroll open-nav">
          <WhoWeAreSection4 />
        </div>
        <div className="slide fp-noscroll open-nav">
          <WhoWeAreSection5 />
        </div>
      </div>
      <div className="section">
        <BusinessAreaSection />
      </div>
      <div className="section">
        <NewsRoom />
      </div>
      <div className="section">
        <LastSection />
      </div>
    </ReactFullpage.Wrapper>
  );
}

const appCss = css`
  .fp-controlArrow {
    /* display: none; */
    background-size: cover;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
    border: none;
    background-color: ${colors.white};
    border-radius: 20px;

    &.fp-next {
      /* display: none; */
      background-image: url('${process.env
    .PUBLIC_URL}/images/common/slide-right.svg');
    }

    &.fp-prev {
      background-image: url('${process.env
    .PUBLIC_URL}/images/common/slide-left.svg');
    }
  }

  .section-page {
    padding-top: ${sizes.navHeight}px;
    position: relative;
  }

  .fp-scroller {
    height: 100%;
  }

  @media (max-width: ${sizes.maxWidth}px) {
    .fp-controlArrow {
      width: 60px;
      height: 60px;
      &.fp-next {
        /* background: none; */
        bottom: 0;
        right: 0;
        top: initial;
        background-image: url(${process.env.PUBLIC_URL +
  '/images/common/arrow-right-white.png'});
        background-color: #55c3e9;
        border-radius: initial;
        background-size: 45px;
        background-position: center center;
      }

      &.fp-prev {
        background: none;
        display: none;
        z-index: -1;
      }
    }
  }
`;

export default App;
