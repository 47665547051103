import { sizes } from '@/interfaces/constants';
import cs from 'classnames';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { getUuid } from '@/utils';

type BusinessAreaNavProps = {
  navOpen: boolean;
  currentActive: number;
  onClickNavItem: (index: number) => void;
};
function BusinessAreaNav({ navOpen, currentActive, onClickNavItem }: BusinessAreaNavProps) {
  const [navItems, setNavItems] = useState([
    {
      text: 'COIN IBT 영업종료',
      active: false,
    },
    {
      text: 'CTC WALLET',
      active: false,
    },
    {
      text: 'BARRY BARRY MARKET',
      active: false,
    },
  ]);

  useEffect(() => {
    setNavState(currentActive);
  }, [currentActive]);

  // function onClickNavItem(slideIndex: number) {}

  function setNavState(slideIndex: number) {
    setNavItems(
      navItems.map((item, itemIndex) => {
        item.active = false;
        if (slideIndex === itemIndex) {
          item.active = true;
        }
        return item;
      }),
    );
  }

  return (
    <nav css={businessAreaNavCss}>
      <div
        className={cs({
          'business-area-nav': true,
          open: navOpen,
        })}
      >
        <div className="business-area-nav-empty-space"></div>
        <div className="business-area-nav-inner">
          {navItems.map(({ text, active }, index) => (
            <div
              className={cs({
                'nav-item': true,
                active,
              })}
              key={getUuid()}
              onClick={() => onClickNavItem(index)}
            >
              {text}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
}

const businessAreaNavCss = css`
  position: absolute;
  /* top: 60px; */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 90;
  .business-area-nav {
    height: 0;
    background-color: #233041;
    overflow: hidden;
    transition: height 1s;

    &.open {
      .business-area-nav-empty-space {
        height: ${sizes.navHeight}px;
      }
      height: calc(${sizes.businessAreaNavHeight}px + ${sizes.navHeight}px);
    }

    .business-area-nav-inner {
      max-width: ${sizes.whoWeAreWidth}px;
      margin: 0 auto;
      /* height: 100%; */
      height: ${sizes.businessAreaNavHeight}px;

      display: flex;
      align-items: center;
      .nav-item {
        min-width: 100px;
        font-size: 1.5rem;
        color: #f9f9f9;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
        margin-top: 6px;
        padding-bottom: 6px;
        border-bottom-width: 2px;
        border-color: transparent;

        &:not(:last-of-type) {
          margin-right: 45px;
        }

        &.active {
          color: #eb5f01;
          border-color: #eb5f01;
        }
      }
    }
  }

  @media (max-width: ${sizes.maxWidth}px) {
    .business-area-nav {
      &.open {
        .business-area-nav-empty-space {
        }
      }

      .business-area-nav-inner {
        align-items: stretch;

        .nav-item {
          min-width: initial;
          flex: 1;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 10px;
          border-bottom-width: 3px;
          white-space: nowrap;
          &:not(:last-of-type) {
            margin: 0;
          }

          &.active {
          }
        }
      }
    }
  }
`;

export default BusinessAreaNav;
