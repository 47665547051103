import { sizes } from '@/interfaces/constants';
import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';
import BasicModal from './BasicModal';

export default function BasePolicyModal(
  props: PropsWithChildren<{
    title: string;
    isShow: boolean;
    onClickCloseButton?: Function;
  }>,
) {
  function onClickCloseButton() {
    if (props.onClickCloseButton !== undefined) {
      props.onClickCloseButton();
    }
  }

  return (
    <BasicModal isShow={props.isShow}>
      <div css={basePolicyModalCss} className="base-policy-modal">
        <div className="modal-header">
          <h1 className="modal-title">{props.title}</h1>
          <div className="close-btn" onClick={onClickCloseButton}>
            X
          </div>
        </div>
        <div className="modal-body-wrap">
          <div className="modal-body">
            <div className="modal-body-inner">{props.children}</div>
          </div>
        </div>
        <div className="modal-footer"></div>
      </div>
    </BasicModal>
  );
}

const basePolicyModalCss = css`
  &.base-policy-modal {
    height: 100%;
    .modal-header {
      border-bottom-width: 1px;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      height: 56px;
      position: relative;

      .modal-title {
        font-size: 3rem;
      }
      .close-btn {
        cursor: pointer;
        font-size: 30px;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
    }
    .modal-body-wrap {
      height: calc(100% - 56px);
      overflow: hidden;
      .modal-body {
        height: 100%;
        overflow-y: auto;
        .modal-body-inner {
          padding: 50px;
        }
      }
    }
    .modal-footer {
    }
  }

  @media screen and (max-width: ${sizes.maxWidth}px) {
    &.base-policy-modal {
      .modal-header {
        .modal-title {
        }
        .close-btn {
        }
      }
      .modal-body-wrap {
        .modal-body {
          .modal-body-inner {
            padding: 20px;
          }
        }
      }
      .modal-footer {
      }
    }
  }
`;
