import React, { useMemo } from 'react';
import { getUuid } from '@/utils';

type TextWithBrTagProps = {
  text: string;
  withDangerouslySetInnerHTML?: boolean;
  className?: string;
};
function TextWithBrTag({
  text,
  withDangerouslySetInnerHTML,
  className
}: TextWithBrTagProps) {
  // const textToSpanMap = text
  //   .trim()
  //   .split('\n')
  //   .map((item) => ({ text: item, key: getUuid() }));
  // const textToSpanMap2 = useMemo(
  //   () =>
  //     text
  //       .trim()
  //       .split('\n')
  //       .map((item) => ({ text: item, key: getUuid() })),
  //   [],
  // );

  // return (
  //   <span>
  //     {textToSpanMap.map((item) => (
  //       <span key={item.key}>
  //         {withDangerouslySetInnerHTML ? (
  //           <>
  //             <span dangerouslySetInnerHTML={{ __html: item.text }}></span>
  //             <br />
  //           </>
  //         ) : (
  //           <>
  //             {item.text} <br />
  //           </>
  //         )}
  //       </span>
  //     ))}
  //   </span>
  // );
  return (
    <span className={className}>
      {text
        .trim()
        .split('\n')
        .map((item, index) => (
          <span key={getUuid()}>
            {/* <span key={JSON.stringify(item) + index}> */}
            {withDangerouslySetInnerHTML ? (
              <>
                <span dangerouslySetInnerHTML={{ __html: item }}></span>
                <br />
              </>
            ) : (
              <>
                {item} <br />
              </>
            )}
          </span>
        ))}
    </span>
  );
}

export default TextWithBrTag;
