import { colors, mixins, sizes } from '@/interfaces/constants';
import { css } from '@emotion/react';
import teams from '@/data/teams.json';
import { getUuid } from '@/utils';
import { useEffect, useState } from 'react';
import axios from 'axios';

type TeamListItem = {
  imgSrc: string;
  names: {
    en: string;
    ko: string;
  };
  position: string;
};

function WhoWeAreSection4() {
  const [teamList, setTeamList] = useState<TeamListItem[]>([]);
  useEffect(() => {
    getTeamList();
  }, []);
  async function getTeamList() {
    try {
      const response = await axios.get('http://125.141.133.23:30001/team-list');
      if (response.status !== 200) throw new Error('status is not 200');
      const { success, result } = response.data;
      if (!success) throw new Error('success is false');

      setTeamList(result);
      console.log('teamList? ', teamList);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <section css={whoWeAreSection4Css} className="section-page">
      <div className="who-we-are-section-4">
        <div className="section-inner">
          <div className="team-list">
            {teamList.map((team, index) => (
              <div className="team-list-item" key={team.imgSrc + team.position + index}>
                <img src={process.env.PUBLIC_URL + team.imgSrc} alt={team.names.ko} className="team-img" />
                <div className="back-panel">
                  <div className="panel-top">
                    <h1 className="name-en">{team.names.en}</h1>
                    <p className="position">{team.position}</p>
                  </div>
                  <div className="panel-bottom">
                    <p className="name-ko">{team.names.ko}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

const whoWeAreSection4Css = css`
  height: 100%;
  background-color: #262626;
  padding-top: ${sizes.whoWeAreNavHeight}px;
  .who-we-are-section-4 {
    padding-top: ${sizes.whoWeAreNavHeight}px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    .section-inner {
      display: flex;
      margin: 0 auto;
      width: 100%;

      .team-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        /* width: calc(
          (100vh - ${sizes.navHeight}px - ${sizes.whoWeAreNavHeight}px) / 4 * 7
        ); */
        width: calc((100vh - ${sizes.navHeight}px - ${sizes.whoWeAreNavHeight}px) / 5 * 7);
        /* 7 X 4 */
        /* padding: 0 96px; */
        margin: 0 auto;

        .team-list-item {
          position: relative;
          &:hover {
            .back-panel {
              display: flex;
            }
          }

          .team-img {
            width: calc((100vh - ${sizes.navHeight}px - ${sizes.whoWeAreNavHeight}px) / 5);
            height: calc((100vh - ${sizes.navHeight}px - ${sizes.whoWeAreNavHeight}px) / 5);
          }
          .back-panel {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            color: ${colors.white};

            display: flex;
            flex-direction: column;
            padding: 18px 12px;
            display: none;

            .panel-top {
              font-family: 'Poppins';
              flex: 1;
              .name-en {
                font-size: 2rem;
                line-height: 1;
                text-transform: uppercase;
              }
              .position {
                color: ${colors.whites['002']};
                font-size: 1.7rem;
              }
            }
            .panel-bottom {
              .name-ko {
                text-align: center;
                font-weight: 700;
                font-size: 1.7rem;
                font-family: 'Nanum Gothic';
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: ${sizes.maxWidth}px) {
    .who-we-are-section-4 {
      padding-bottom: 60px;
      .section-inner {
        .team-list {
          /* width: calc(
            (100vh - ${sizes.navHeight}px - ${sizes.whoWeAreNavHeight}px) / 5 *
              7
          ); */
          .team-list-item {
            &:hover {
              .back-panel {
              }
            }

            .team-img {
              width: calc((100vh - ${sizes.navHeight}px - ${sizes.whoWeAreNavHeight}px - 60px) / 8);
              height: calc((100vh - ${sizes.navHeight}px - ${sizes.whoWeAreNavHeight}px - 60px) / 8);
            }
            .back-panel {
              padding: 5px;
              .panel-top {
                .name-en {
                  font-size: 1.5rem;
                }
                .position {
                  font-size: 1.3rem;
                  line-height: 1;
                }
              }
              .panel-bottom {
                .name-ko {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default WhoWeAreSection4;
