export const sizes = {
  navHeight: 60,
  navWidth: 1600,
  contentsWidth: 1440,
  whoWeAreWidth: 1200,
  whoWeAreNavHeight: 57,
  businessAreaNavHeight: 57,
  footerHeight: 185,
  maxWidth: 800,
};

export const mixins = {
  setMaxWidth: `
    max-width: ${sizes.contentsWidth}px;
    margin: 0 auto;
  `,
  setWidth: `
    width: ${sizes.contentsWidth}px;
    margin: 0 auto;
  `,
  boxShadowColor: 'rgba(0,0,0,0.3)',
};

export const colors = {
  white: '#fff',
  black: '#000',

  whites: {
    '001': '#fefefe',
    '002': '#F9F9F9',
  },

  blue: {
    '001': '#0091db',
    '002': '#0098e2',
  },
  gray: {
    '001': '#5c5c5c',
  },
  yellow: {},
  purple: {},
  brown: {},
  pink: {},
  green: {
    '001': '#bff70d',
  },
};
