import { colors, mixins, sizes } from '@/interfaces/constants';
import { useRootState } from '@/store/RootStore';
import { getUuid } from '@/utils';
import { css } from '@emotion/react';
import classNames from 'classnames';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function WhoWeAreSection5() {
  const { fullpageApi } = useRootState();
  const [donationList, setDonationList] = useState([
    {
      coinName: 'BTC',
      coinAddress: 'bc1qx8trxeknuhng6nfemknudjvwxhljdh74umpg0k',
      copied: false,
    },
    {
      coinName: 'ETH',
      coinAddress: '0xFF82aCb351855B1fA5A88d0d4C0a44c4d587bf8B',
      copied: false,
    },
    {
      coinName: 'BNB',
      coinAddress: 'bnb124m7pewznz00q77sketvdt47fap9nlznrlcj9g',
      copied: false,
    },
  ]);

  function addressCopied(index: number) {
    if (donationList[index].copied) {
      return;
    }

    toggleCopied(index);
    const timeoutId = setTimeout(() => {
      toggleCopied(index);
      clearTimeout(timeoutId);
    }, 1000);
  }

  function toggleCopied(index: number) {
    setDonationList(
      donationList.map((item, _index) => {
        if (_index === index) {
          item.copied = !item.copied;
        }
        return item;
      }),
    );
  }

  return (
    <section css={whoWeAreSection5Css} className="section-page">
      <div className="who-we-are-section-5">
        <div className="section-inner">
          <div className="donation">
            <h1 className="page-title">We Share Donations</h1>
            <div className="address-list">
              <ul>
                {donationList.map((item, index) => (
                  <li key={item.coinAddress + item.coinName}>
                    <div className="list-item">
                      <span className="coin-name">{item.coinName}</span>
                      <input type="text" className="coin-address" value={item.coinAddress} disabled />
                      <div className="copy-button-wrap">
                        <CopyToClipboard text={item.coinAddress} onCopy={() => addressCopied(index)}>
                          <div className="copy-button">
                            <img
                              src={process.env.PUBLIC_URL + '/images/common/copy-white.png'}
                              alt="copy"
                              className="copy-image"
                            />
                          </div>
                        </CopyToClipboard>
                        <div className="copied-area">
                          <div
                            className={classNames({
                              copied: true,
                              on: item.copied,
                            })}
                          >
                            Copied!
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div
          className="left-arrow-wrap"
          onClick={() => {
            fullpageApi?.moveTo(2, 0);
          }}
        >
          <img src={process.env.PUBLIC_URL + '/images/common/arrow-right-white.png'} alt="" />
        </div>
      </div>
    </section>
  );
}

const whoWeAreSection5Css = css`
  height: 100%;
  padding-top: ${sizes.whoWeAreNavHeight}px;
  background-color: #262626;
  background-image: url('${process.env.PUBLIC_URL}/images/who-we-are-section/5/bg.jpg');
  background-size: cover;

  .who-we-are-section-5 {
    padding-top: ${sizes.whoWeAreNavHeight}px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${mixins.setMaxWidth}
    height: 100%;

    .section-inner {
      display: flex;
      max-width: ${sizes.contentsWidth}px;
      margin: 0 auto;
      justify-content: center;
      align-items: center;

      .donation {
        color: ${colors.white};
        margin-bottom: 150px;
        .page-title {
          font-size: 8rem;
          margin-bottom: 50px;
        }
        .address-list {
          font-size: 4rem;
          > ul {
            padding-left: 20px;
            > li {
              :not(:last-of-type) {
                margin-bottom: 40px;
              }
              .list-item {
                display: flex;
                justify-content: flex-start;
                /* align-items: center; */

                .coin-name {
                  margin-right: 20px;
                  font-weight: 700;
                  font-family: 'Poppins';
                  text-transform: uppercase;
                  width: 13%;

                  :after {
                    content: '.';
                  }
                }
                .coin-address {
                  margin: 5px 0;
                  background-color: rgba(255, 255, 255, 0.15);
                  border-color: rgba(255, 255, 255, 0.45);
                  border-width: 1px;
                  border-radius: 7px;
                  padding: 3px 10px;
                  flex: 1;
                  outline: none;
                  color: ${colors.white};
                  font-size: 2rem;
                  margin-right: 20px;
                }
                .copy-button-wrap {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  .copy-button {
                    background-color: rgba(255, 255, 255, 0.15);
                    border-color: rgba(255, 255, 255, 0.45);
                    border-width: 1px;
                    border-radius: 16px;
                    padding: 8px;
                    padding-right: 6px;
                    cursor: pointer;
                    :hover {
                      background-color: rgba(255, 255, 255, 0.05);
                    }

                    .copy-image {
                      width: 25px;
                    }
                  }

                  .copied-area {
                    position: absolute;
                    left: 120%;

                    .copied {
                      font-size: 1.4rem;
                      position: relative;
                      padding: 10px 30px;
                      background-color: rgba(255, 255, 255, 0.15);
                      border-color: rgba(255, 255, 255, 0.45);
                      border-width: 1px;
                      border-radius: 16px;
                      transform: scale(0);
                      transition: transform 0.2s;
                      transform-origin: 0%;

                      &.on {
                        transform: scale(1);
                      }

                      :before {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .left-arrow-wrap {
      display: none;
    }
  }

  @media (max-width: ${sizes.maxWidth}px) {
    .who-we-are-section-5 {
      background-position: center center;
      background-color: rgba(0, 0, 0, 0.5);
      padding-left: 20px;
      padding-right: 20px;
      .section-inner {
        .donation {
          margin-bottom: initial;
          .page-title {
            font-size: 5rem;
          }
          .address-list {
            > ul {
              > li {
                :not(:last-of-type) {
                }
                .list-item {
                  flex-wrap: wrap;
                  .coin-name {
                    width: 100%;
                    :after {
                    }
                  }
                  .coin-address {
                  }
                  .copy-button-wrap {
                    .copy-button {
                      :hover {
                      }

                      .copy-image {
                      }
                    }

                    .copied-area {
                      left: -68%;
                      top: 120%;

                      .copied {
                        transform-origin: top;
                        &.on {
                        }

                        :before {
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .left-arrow-wrap {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60px;
        height: 60px;
        background-color: #55c3e9;
        padding: 7.5px;
        cursor: pointer;

        > img {
          width: 100%;
          transform: rotate(180deg);
        }
      }
    }
  }
`;

export default WhoWeAreSection5;
