import { sizes } from '@/interfaces/constants';
import { css } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type FooterProps = {
  openPrivacyPolicyModal: Function;
  openTermsModal: Function;
};
function Footer(props: FooterProps) {
  const { t } = useTranslation();
  const familySites = [
    {
      value: t('common.oneHeartSmart'),
      link: 'http://oneheartsmart.com',
    },
    {
      value: 'Cybertronchain',
      link: 'https://cybertronchain.com',
    },
    {
      value: 'COIN IBT',
      link: 'https://coinibt.io/',
    },
    {
      value: 'Barrybarries Market',
      link: 'https://barrybarries.kr/',
    },
  ];

  const [selectValue, setSelectValue] = useState('default');

  function goLink(e: React.ChangeEvent<HTMLSelectElement>) {
    setSelectValue(e.target.value);
    const targetLink = familySites.find((item) => item.value === e.target.value)?.link;
    if (!targetLink) {
      return;
    }

    const win = window.open(targetLink, '_blank');
    win?.focus();
  }

  return (
    <div css={footerCss}>
      <div className="footer-container">
        <div className="footer-left">
          <div className="footer-left-1">
            <img src={process.env.PUBLIC_URL + '/images/footer/footer-logo.png'} alt="logo" className="footer-logo" />
            <img
              src={process.env.PUBLIC_URL + '/images/footer/footer-logo-mobile.png'}
              alt="logo"
              className="footer-logo-mobile"
            />
            <div className="personal-info-rule">
              <span className="open-policy" onClick={() => props.openPrivacyPolicyModal()}>
                {t('footer.privacyPolicy')}
              </span>
              <span> | </span>
              <span
                className="open-terms"
                // onClick={() => props.openTermsModal()}
              >
                {t('footer.termsOfService')}
              </span>
            </div>
          </div>
          <div className="footer-left-2">
            {t('lastSection.companyAddress')} | {t('common.ceo')} {t('common.ceoName')} |{' '}
            {t('footer.companyRegistrationNumber')} 849-88-01299
          </div>
          <div className="footer-left-3">Copyright 2021 Hansbiotech CO., Ltd. All rights reserved.</div>
        </div>
        <div className="footer-right">
          <div className="family-site">
            <select onChange={goLink} value={selectValue}>
              <option value="default" disabled hidden>
                Family Site
              </option>
              {familySites.map((item) => (
                <option key={JSON.stringify(item)} value={item.value}>
                  {item.value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

const footerCss = css`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${sizes.footerHeight}px;
  background-color: #efefef;
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 22px 0;
    display: flex;

    .footer-left {
      flex: 1;

      .footer-left-1 {
        margin-bottom: 37px;
        display: flex;
        align-items: center;
        .footer-logo {
          margin-right: 34px;
        }
        .footer-logo-mobile {
          display: none;
        }
        .personal-info-rule {
          color: #080808;
          font-size: 1.4rem;

          .open-policy {
            cursor: pointer;
            font-size: 1.5em;
            font-weight: 700;
          }
          .open-terms {
            /* cursor: pointer; */
          }
        }
      }
      .footer-left-2 {
        font-size: 1.4rem;
        color: #939393;
        margin-bottom: 2rem;
      }
      .footer-left-3 {
        font-size: 1.4rem;
        color: #939393;
      }
    }
    .footer-right {
      .family-site {
        > select {
          padding: 13px 18px;
          width: 220px;
        }
      }
    }
  }

  @media (max-width: ${sizes.maxWidth}px) {
    height: initial;
    background-color: #2c2c2c;
    .footer-container {
      padding: 16px 0;
      .footer-left {
        padding: 0 30px;
        .footer-left-1 {
          width: 40%;
          margin-bottom: 16px;
          .footer-logo {
            display: none;
          }
          .footer-logo-mobile {
            display: initial;
            width: 100%;
          }
          .personal-info-rule {
            display: none;
          }
        }
        .footer-left-2 {
          padding-right: 40px;
          word-break: keep-all;
          margin-bottom: 3px;
        }
        .footer-left-3 {
        }
      }
      .footer-right {
        .family-site {
          display: none;
          > select {
          }
        }
      }
    }
  }
`;

export default Footer;
