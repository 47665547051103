import { css } from '@emotion/react';
import React, { PropsWithChildren, useState } from 'react';

type TouchDirection = 'top' | 'bottom' | 'left' | 'right';
type TouchPanelProps = {
  className?: string;
  style?: React.CSSProperties;
  touchSensitivity?: number;
  touchHandler: (touchDirection: TouchDirection, event: React.TouchEvent<HTMLElement>) => void;
  stopPropagation?: boolean;
};
function TouchPanel({ touchSensitivity = 55, ...props }: PropsWithChildren<TouchPanelProps>) {
  const [isTouching, setIsTouching] = useState(false);
  const [touchStartPoint, setTouchStartPoint] = useState({ x: 0, y: 0 });

  function onTouchStartCapture(event: React.TouchEvent<HTMLDivElement>) {
    setIsTouching(true);
    const { changedTouches } = event;
    const { clientX: x, clientY: y } = changedTouches[0];
    setTouchStartPoint({ x, y });
  }
  function onTouchEndCapture(event: React.TouchEvent<HTMLDivElement>) {
    // console.log('touchEnd', event);
    setIsTouching(false);
  }
  function onTouchMove(event: React.TouchEvent<HTMLElement>) {
    // console.log('touchMove', event);
    if (props.stopPropagation) {
      event.stopPropagation();
    }
    if (!isTouching) {
      return;
    }

    const { changedTouches } = event;
    const { clientX: x, clientY: y } = changedTouches[0];
    const xMove = x - touchStartPoint.x;
    const yMove = y - touchStartPoint.y;
    let isHandling = false;
    // console.log({ xMove, yMove });

    // if (Math.abs(xMove) < props.touchSensitivity && Math.abs(yMove) < props.touchSensitivity) {
    //   return;
    // }

    if (touchSensitivity < Math.abs(xMove)) {
      if (xMove < 0) {
        // move right
        props.touchHandler('right', event);
      } else {
        // move left
        props.touchHandler('left', event);
      }
      isHandling = true;
    }
    if (touchSensitivity < Math.abs(yMove)) {
      if (yMove < 0) {
        // move bottom
        props.touchHandler('bottom', event);
      } else {
        // move top
        props.touchHandler('top', event);
      }
      isHandling = true;
    }

    if (isHandling) {
      setTouchStartPoint({ x: 0, y: 0 });
      setIsTouching(false);
    }
  }

  return (
    <div
      css={touchPanelCss}
      onTouchStartCapture={onTouchStartCapture}
      onTouchEndCapture={onTouchEndCapture}
      onTouchMoveCapture={onTouchMove}
      className={props.className}
      style={props.style}
    >
      {/* <div className="touchPanel-container">{props.children}</div> */}
      {props.children}
    </div>
  );
}

const touchPanelCss = css`
  .touchPanel-container {
  }
`;

export default TouchPanel;
