import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import marked from 'marked';
import { sizes } from '@/interfaces/constants';

export default function MarkdownLenderer(props: { markdownData: string }) {
  const markdownId = `markdown-lenderer-${v4()}`;
  console.log('markdownId? ', markdownId);
  const [markedData, setMarkedData] = useState('');

  useEffect(() => {
    marked.setOptions({
      renderer: new marked.Renderer(),
      gfm: true,
      headerIds: false,
      // tables: true,
      breaks: true,
      pedantic: false,
      smartLists: true,
      smartypants: false,
    });

    setMarkedData(marked(props.markdownData));
  }, [props.markdownData]);

  return (
    <div className="markdown-lenderer-container" css={markdownLendererCss} id={markdownId}>
      <div dangerouslySetInnerHTML={{ __html: markedData }}></div>
    </div>
  );
}

const markdownLendererCss = css`
  &.markdown-lenderer-container {
    font-size: 2rem;
    word-break: keep-all;

    ol {
      padding-left: 40px;
      line-height: 1.5;
    }

    ul {
      padding-left: 40px;
      list-style: disc;
      margin-bottom: 14px;
    }
    h1,
    h2,
    h3 {
      margin-top: 50px;
      margin-bottom: 20px;
    }
    h2 {
      font-weight: 700;
      font-size: 32px;
    }
    p {
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
    p + h2,
    ol + h2,
    ul + h2,
    table + h2 {
      margin-top: 50px;
    }

    table {
      width: 100%;
      border: 1px solid black;
      box-sizing: border-box;
      text-align: center;
      color: #000;
      border-collapse: collapse;
      tr {
        th {
          border: 1px solid black;
          text-align: center;
          background-color: rgba(0, 0, 0, 0.1);
          padding: 10px 0;
        }

        td {
          border: 1px solid black;
          padding: 10px 0;
        }
      }
    }
  }

  @media screen and (max-width: ${sizes.maxWidth}px) {
    &.markdown-lenderer-container {
      h1 {
      }
      h2 {
        font-size: 24px;
      }
      p {
        font-size: 14px;
        text-align: left;
      }
      ul {
        font-size: 14px;
        text-align: left;
      }

      li {
        font-size: 13px;
        line-height: 1.4;
      }
    }
  }
`;
