import TextWithBrTag from '@/components/common/TextWithBrTag';
import { colors, sizes } from '@/interfaces/constants';
import { css } from '@emotion/react';
import classNames from 'classnames';
import { useState } from 'react';
import BusinessAreaNav from '@/components/BusinessAreaNav';
import SVGFullSize from '@/components/SVG/SVGFullSize';
import LinkWrap from '@/components/common/LinkWrap';
import TouchPanel from '@/components/TouchPanel';
import { useRootState } from '@/store/RootStore';
import { useTranslation } from 'react-i18next';

const transitionTime = '1s';
function BusinessAreaSection() {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [isDetailView, setIsDetailView] = useState(false);
  const [isTouching, setIsTouching] = useState(false);
  const [touchStartPoint, setTouchStartPoint] = useState({ x: 0, y: 0 });
  const touchSensitivity = 55;
  const { fullpageApi } = useRootState();
  const { t } = useTranslation();

  // const { fullpageState } = useRootState();

  // useEffect(() => {
  //   if (
  //     fullpageState?.lastEvent === 'afterLoad' &&
  //     // fullpageState?.lastEvent === 'onLeave' &&
  //     fullpageState?.destination.index !== 2
  //   ) {
  //     setIsDetailView(false);
  //     setCurrentSectionIndex(0);
  //   }
  // }, [fullpageState]);

  const sectionList = [
    {
      // COIN IBT
      className: 'coin-ibt-section',
      sectionTitle: 'COIN IBT 영업종료',
      contentTexts: {
        subtitle: t('businessArea.coinIBT.subtitle'),
        desc: t('businessArea.coinIBT.desc'),
      },
      link: 'https://www.coinibt.io',
      detail: {
        dislay: 'block',
        mainDesc: t('businessArea.coinIBT.detail.mainDesc'),
        boxContents: [
          {
            imgSrc: '/images/business-area-section/coin-ibt-detail/icon1.png',
            title: t('businessArea.coinIBT.detail.boxContents.t1.title'),
            content: t('businessArea.coinIBT.detail.boxContents.t1.content'),
          },
          {
            imgSrc: '/images/business-area-section/coin-ibt-detail/icon2.png',
            title: t('businessArea.coinIBT.detail.boxContents.t2.title'),
            content: t('businessArea.coinIBT.detail.boxContents.t2.content'),
          },
          {
            imgSrc: '/images/business-area-section/coin-ibt-detail/icon3.png',
            title: t('businessArea.coinIBT.detail.boxContents.t3.title'),
            content: t('businessArea.coinIBT.detail.boxContents.t3.content'),
          },
        ],
        bottomTitle: t('businessArea.coinIBT.detail.bottomTitle'),
        bottomContent: t('businessArea.coinIBT.detail.bottomContent'),
        boxTitleColor: '#0083fd',
        boxContentColor: '#121212',
        boxBgColor: '0, 131, 253',
        boxBorderColor: '0, 131, 253',
      },
    },
    {
      // CTC WALLET
      className: 'ctc-wallet-section',
      sectionTitle: 'CTC WALLET',
      contentTexts: {
        subtitle: t('businessArea.ctcWallet.subtitle'),
        desc: t('businessArea.ctcWallet.desc'),
      },
      link: '',
      detail: {
        dislay: 'none',
        mainDesc: t('businessArea.ctcWallet.detail.mainDesc'),
        boxContents: [
          {
            imgSrc: '/images/business-area-section/ctc-wallet-detail/icon1.png',
            title: t('businessArea.ctcWallet.detail.boxContents.t1.title'),
            content: t('businessArea.ctcWallet.detail.boxContents.t1.content'),
          },
          {
            imgSrc: '/images/business-area-section/ctc-wallet-detail/icon2.png',
            title: t('businessArea.ctcWallet.detail.boxContents.t2.title'),
            content: t('businessArea.ctcWallet.detail.boxContents.t2.content'),
          },
          {
            imgSrc: '/images/business-area-section/ctc-wallet-detail/icon3.png',
            title: t('businessArea.ctcWallet.detail.boxContents.t3.title'),
            content: t('businessArea.ctcWallet.detail.boxContents.t3.content'),
          },
        ],
        bottomTitle: t('businessArea.ctcWallet.detail.bottomTitle'),
        bottomContent: t('businessArea.ctcWallet.detail.bottomContent'),
        boxTitleColor: '#eb5f01',
        boxBgColor: '255, 234, 97',
        boxBorderColor: '253, 185, 0',
      },
    },
    {
      // BB MARKET
      className: 'bb-market-section',
      sectionTitle: 'BARRY BARRY MARKET',
      contentTexts: {
        subtitle: t('businessArea.bbMarket.subtitle'),
        desc: t('businessArea.bbMarket.desc'),
      },
      link: 'https://barrybarry.market/',
      detail: {
        dislay: 'block',
        mainDesc: t('businessArea.bbMarket.detail.mainDesc'),
        boxContents: [
          {
            imgSrc: '/images/business-area-section/bb-market-detail/icon1.png',
            title: t('businessArea.bbMarket.detail.boxContents.t1.title'),
            content: t('businessArea.bbMarket.detail.boxContents.t1.content'),
          },
          {
            imgSrc: '/images/business-area-section/bb-market-detail/icon2.png',
            title: t('businessArea.bbMarket.detail.boxContents.t2.title'),
            content: t('businessArea.bbMarket.detail.boxContents.t2.content'),
          },
          {
            imgSrc: '/images/business-area-section/bb-market-detail/icon3.png',
            title: t('businessArea.bbMarket.detail.boxContents.t3.title'),
            content: t('businessArea.bbMarket.detail.boxContents.t3.content'),
          },
        ],
        // bottomTitle: 'Coin IBT에서 발행하고 관리하는 E―TP3,E-MC로 거래가 가능한 플랫폼',
        bottomTitle: t('businessArea.bbMarket.detail.bottomTitle'),
        bottomContent: t('businessArea.bbMarket.detail.bottomContent'),
        boxContentColor: '#43b501',
        boxBgColor: '130, 203, 88',
        boxBorderColor: '67, 170, 8',
      },
    },
  ];

  function changeSection(index: number) {
    setCurrentSectionIndex(index);
  }

  function onTouchMove(event: React.TouchEvent<HTMLElement>) {
    // console.log('touchMove', event);
    if (!isTouching) {
      return;
    }
    const { changedTouches } = event;
    const { clientX: x, clientY: y } = changedTouches[0];
    const xMove = x - touchStartPoint.x;
    const yMove = y - touchStartPoint.y;
    // console.log({ xMove, yMove });

    if (Math.abs(xMove) < touchSensitivity) {
      return;
    }

    if (xMove < 0) {
      // move right
      moveSwipe('right');
    } else {
      // move left
      moveSwipe('left');
    }

    setTouchStartPoint({ x: 0, y: 0 });
    setIsTouching(false);
  }

  function moveSwipe(direction: 'left' | 'right') {
    if (direction === 'left') {
      if (currentSectionIndex <= 0) {
        return;
      }
      setCurrentSectionIndex(currentSectionIndex - 1);
    } else if (direction === 'right') {
      if (2 <= currentSectionIndex) {
        return;
      }
      setCurrentSectionIndex(currentSectionIndex + 1);
    }
  }

  return (
    <section css={businessAreaSectionCss}>
      <TouchPanel
        // touchSensitivity={55}
        stopPropagation
        touchHandler={(touchDirection) => {
          if (touchDirection === 'left' || touchDirection === 'right') {
            moveSwipe(touchDirection);
          } else if (touchDirection === 'top') {
            fullpageApi?.moveSectionUp();
          } else if (touchDirection === 'bottom') {
            fullpageApi?.moveSectionDown();
          }
        }}
        style={{
          height: '100%',
        }}
      >
        <div className="business-area-section">
          <div className="business-area-section-inner detail-view">
            <BusinessAreaNav
              navOpen={isDetailView}
              currentActive={currentSectionIndex}
              onClickNavItem={(index) => setCurrentSectionIndex(index)}
            />
            <div
              className={classNames({
                'main-content': true,
                reduce: isDetailView,
              })}
              style={{
                // transform: `translateX(-${100 * currentSectionIndex}vw)`,
                // @dev IE11 때문에 left로 바꿈
                left: `-${100 * currentSectionIndex}vw`,
              }}
            >
              {sectionList.map((item) => (
                <section
                  className={classNames(['content-section', item.className])}
                  key={item.className + item.sectionTitle}
                >
                  {/* <div className="text">{item.sectionTitle}</div> */}
                  <div
                    className={classNames({
                      'top-text': true,
                      hide: isDetailView,
                    })}
                  >
                    <div className="section-name">OUR BUSINESS</div>
                    <div className="text01" dangerouslySetInnerHTML={{ __html: t('businessArea.text01') }}></div>
                  </div>
                  <div className="contents-text">
                    <div
                      className={classNames({
                        'contents-text-inner': true,
                        hide: isDetailView,
                      })}
                    >
                      <div className="contents-subtitle">{item.contentTexts.subtitle}</div>
                      <div className="contents-section-title">{item.sectionTitle}</div>
                      <div className="contents-desc">
                        <TextWithBrTag text={item.contentTexts.desc} />
                      </div>
                      <div className="more-button" onClick={() => setIsDetailView(true)}>
                        <span>{t('common.learnMore')}</span>
                        <img
                          src={process.env.PUBLIC_URL + '/images/common/arrow-right-white.png'}
                          alt=""
                          className="arrow-right-white"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames({
                      'svg-wrap': true,
                      show: isDetailView,
                    })}
                    onClick={() => setIsDetailView(false)}
                  >
                    <SVGFullSize fill="#fff" size={25} />
                  </div>
                </section>
              ))}
            </div>
            <div
              className={classNames({
                'main-content-navi-container': true,
                hide: isDetailView,
              })}
            >
              <div className="main-content-navi">
                <div className="navi-text-area">
                  {sectionList.map((item, index) => (
                    <div
                      className={classNames({
                        'navi-text': true,
                        active: index === currentSectionIndex,
                      })}
                      key={item.className + item.sectionTitle}
                    >
                      <span
                        className="navi-text-span"
                        onClick={() => {
                          changeSection(index);
                        }}
                      >
                        # {item.sectionTitle}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="navi-bottom-bar">
                  <div
                    className="active-bar"
                    style={{
                      left: `${300 * currentSectionIndex}px`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div
              className={classNames({
                'detail-view-container': true,
                up: isDetailView,
              })}
              style={{
                // transform: `translateX(-${100 * currentSectionIndex}vw)`,
                // @dev IE11 때문에 left로 바꿈
                left: `-${100 * currentSectionIndex}vw`,
              }}
            >
              {sectionList.map((item) => (
                <div className="detail-section" key={item.className + item.sectionTitle}>
                  <div className="detail-section-inner">
                    <div className="detail-left">
                      <div className="detail-subtitle">{item.contentTexts.subtitle}</div>
                      <div className="detail-section-title">{item.sectionTitle}</div>
                    </div>
                    <div className="detail-right">
                      <div className="detail-right-top">
                        <div className="detail-main-desc">{item.detail.mainDesc}</div>
                        <div className="box-list">
                          {item.detail.boxContents.map((boxItem) => (
                            <div
                              className={classNames({
                                'box-item': true,
                              })}
                              key={boxItem.imgSrc + boxItem.title}
                              style={{
                                backgroundColor: `rgba(${item.detail.boxBgColor}, 0.1)`,
                                borderColor: `rgba(${item.detail.boxBorderColor}, 0.1)`,
                              }}
                            >
                              <div className="box-image-wrap">
                                <img
                                  src={process.env.PUBLIC_URL + boxItem.imgSrc}
                                  alt={boxItem.title}
                                  className="box-image"
                                />
                              </div>
                              <div className="box-title" style={{ color: item.detail.boxTitleColor }}>
                                {boxItem.title}
                              </div>
                              <div
                                className={classNames({
                                  'box-content': true,
                                  'content-only': boxItem.title === '',
                                })}
                                style={{ color: item.detail.boxContentColor }}
                              >
                                {boxItem.content}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="detail-right-bottom">
                        <div className="detail-right-bottom-inner">
                          <div className="bottom-title-area">
                            <h1 className="bottom-title" dangerouslySetInnerHTML={{ __html: item.detail.bottomTitle }}>
                              {/* {item.detail.bottomTitle} */}
                            </h1>
                            {/* <div className="go-to-site">사이트로 이동</div> */}
                            <LinkWrap link={item.link} className="go-to-site">
                              <span style={{ display: item.detail.dislay }}>{t('businessArea.moveSite')}</span>
                            </LinkWrap>
                          </div>
                          <div className="detail-bottom-content">{item.detail.bottomContent}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </TouchPanel>
    </section>
  );
}

const businessAreaSectionCss = css`
  height: 100%;
  .business-area-section {
    height: 100%;
    .business-area-section-inner {
      height: 100%;
      width: 100vw;
      overflow: hidden;
      position: relative;
      .main-content {
        position: relative;
        height: 100%;
        display: flex;
        width: 300vw;
        transition: transform ${transitionTime}, left ${transitionTime}, height ${transitionTime};
        /* transform: translateX(-40vw); */

        &.reduce {
          height: 40%;
          .content-section {
            background-position: 0 28%;
          }
        }

        .content-section {
          height: 100%;
          padding-top: ${sizes.navHeight}px;
          background-size: cover;
          width: 100%;
          position: relative;
          transition: background-position ${transitionTime};

          .top-text {
            margin-top: 53px;
            text-align: center;

            transition: transform ${transitionTime};
            &.hide {
              /* transform: translateY(-245px); */
              transform: translateY(-195%);
            }

            .section-name {
              font-size: 1.8rem;
              font-family: 'Poppins';
              font-weight: 700;
              margin-bottom: 11px;
            }
            .text01 {
              font-size: 3rem;
              line-height: 4.2rem;
              font-weight: 700;
              /* font-family: 'Nanum Gothic'; */
            }
          }

          .contents-text {
            position: absolute;
            /* left: 78px; */
            left: 0;
            padding-left: 78px;
            bottom: 195px;
            color: ${colors.white};
            overflow: hidden;

            .contents-text-inner {
              transition: transform ${transitionTime};
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              &.hide {
                transform: translateX(-130%);
              }
              .contents-subtitle {
                font-size: 1.8rem;
                font-weight: 700;
                margin-bottom: 0.4rem;
                background-color: rgba(0, 0, 0, 0.3);
                padding: 2px 5px;
                border-radius: 5px;
              }

              .contents-section-title {
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 3rem;
                margin-bottom: 3rem;
                line-height: 1;
                background-color: rgba(0, 0, 0, 0.3);
                padding: 2px 5px;
                border-radius: 5px;
              }

              .contents-desc {
                font-size: 1.5rem;
                line-height: 2.5rem;
                margin-bottom: 2rem;
              }

              .more-button {
                background-color: #eb5f01;
                font-size: 1.6rem;
                line-height: 3;
                padding: 0 17px 0 17px;
                cursor: pointer;
                display: flex;
                align-items: center;

                .arrow-right-white {
                  width: 30px;
                  margin-left: 70px;
                }
              }
            }
          }

          .svg-wrap {
            position: absolute;
            right: 20px;
            bottom: 20px;
            cursor: pointer;
            transition: transform ${transitionTime};
            transition-delay: ${transitionTime};
            transform: scale(0);

            &.show {
              transform: scale(1);
              /* animation-name: showani;
              animation-duration: ${transitionTime};
              animation-delay: ${transitionTime};
              animation-iteration-count: infinite; */
              /* animation: showani 2s ${transitionTime} ease infinite; */
            }
            @keyframes showani {
              0% {
                transform: scale(1);
              }
              50% {
                transform: scale(1.2);
              }
              100% {
                transform: scale(1);
              }
            }
          }
        }

        .coin-ibt-section {
          background-image: url('${process.env.PUBLIC_URL}/images/business-area-section/coin-ibt/bg.png');
        }
        .ctc-wallet-section {
          background-image: url('${process.env.PUBLIC_URL}/images/business-area-section/ctc-wallet/bg.png');
        }
        .bb-market-section {
          background-image: url('${process.env.PUBLIC_URL}/images/business-area-section/bb-market/bg.png');
        }
      }
      .main-content-navi-container {
        position: absolute;
        left: 0;
        bottom: 105px;
        width: 100%;
        display: flex;
        justify-content: center;
        color: ${colors.white};

        transition: transform ${transitionTime};

        &.hide {
          transform: translateY(315%);
        }

        /*  */
        /* transition: transform 3s; */
        /* transform: translateY(300px); */
        /*  */
        .main-content-navi {
          width: 900px;
          .navi-text-area {
            display: flex;

            .navi-text {
              flex: 1;
              font-size: 1.8rem;
              font-family: 'Poppins';
              text-align: center;
              font-weight: 300;
              margin-bottom: 20px;

              .navi-text-span {
                cursor: pointer;
              }

              &.active {
                font-weight: 700;
              }
            }
          }

          .navi-bottom-bar {
            position: relative;
            height: 2px;
            background-color: #9b9b9b;

            .active-bar {
              background-color: #eb5f01;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 300px;
              transition: left ${transitionTime};
              /* left: calc(300px * 2); */
            }
          }
        }
      }

      .detail-view-container {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: ${colors.white};
        display: flex;
        width: 300vw;
        height: 60%;
        transition: top ${transitionTime}, left ${transitionTime};

        &.up {
          top: 40%;
        }

        .detail-section {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;

          .detail-section-inner {
            max-width: 1200px;
            height: 100%;
            display: flex;
            flex-direction: row;
            /* align-items: center; */
            padding-top: 35px;
            padding-bottom: 10px;

            .detail-left {
              width: 20%;
              font-weight: 700;
              .detail-subtitle {
                font-size: 1.6rem;
                margin-bottom: 11px;
              }
              .detail-section-title {
                font-family: 'Poppins';
                font-size: 1.8rem;
                line-height: 1;
              }
            }
            .detail-right {
              width: 100%;

              .detail-right-top {
                padding-bottom: 10px;
                border-bottom-width: 1px;
                border-color: #dddddd;
                margin-bottom: 10px;
                /* height: 72%; */
                height: 70%;

                .detail-main-desc {
                  font-size: 1.6rem;
                  line-height: 2.4rem;
                  /* margin-bottom: 45px; */
                  height: 45%;
                }
                .box-list {
                  /* height: 60%; */
                  height: 54%;
                  display: flex;

                  .box-item {
                    padding: 22px 34px;
                    border-radius: 5px;
                    border-width: 1px;
                    width: calc(100% / 3);
                    display: flex;
                    flex-flow: column;

                    &:not(:last-of-type) {
                      margin-right: 48px;
                    }

                    .box-image-wrap {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-bottom: 15px;
                      flex: 1;
                      .box-image {
                      }
                    }
                    .box-title {
                      font-size: 1.7rem;
                      font-weight: 700;
                      margin-bottom: 12px;
                      text-align: center;
                      width: 100%;
                    }
                    .box-content {
                      font-size: 1.4rem;
                      line-height: 1.8rem;
                      text-align: center;
                      width: 100%;
                    }
                  }
                }
              }
              .detail-right-bottom {
                .bottom-title-area {
                  display: flex;
                  align-items: center;
                  margin-bottom: 16px;
                  .bottom-title {
                    display: inline-block;
                    font-size: 1.8rem;
                    font-weight: 700;
                    margin-right: 22px;
                    /* word-break: keep-all; */
                  }
                  .go-to-site {
                    display: inline-block;
                    background-color: #eb5f01;
                    color: ${colors.white};
                    font-size: 1.4rem;
                    padding: 10px 22px;
                    cursor: pointer;
                  }
                  span.go-to-site {display:none;}
                }
                .detail-bottom-content {
                  font-size: 1.5rem;
                  line-height: 2.4rem;
                  color: #636363;
                  padding-right: 33%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: ${sizes.maxWidth}px) {
    .business-area-section {
      .business-area-section-inner {
        .main-content {
          &.reduce {
            height: 50%;
            .content-section {
              background-position: center;
            }
          }

          .content-section {
            background-position: center top;
            &.ctc-wallet-section {
              background-position: 21%;
            }
            &.bb-market-section {
              background-position: 68%;
            }
            .top-text {
              margin-top: 20px;
              &.hide {
              }

              .section-name {
              }
              .text01 {
              }
            }

            .contents-text {
              bottom: 50px;
              padding-left: 30px;
              .contents-text-inner {
                &.hide {
                  transform: translateX(0);

                  .contents-desc {
                    margin-bottom: 0;
                  }
                  .more-button {
                    /* transform: scale(0);
                    display: none; */
                    max-height: 0;
                    overflow: hidden;
                  }
                }
                .contents-subtitle {
                  font-size: 3rem;
                }

                .contents-section-title {
                }

                .contents-desc {
                  font-size: 2rem;
                  transition: margin-bottom 1s;

                  > span {
                    span:not(:first-of-type) {
                      display: none;
                    }
                  }
                }

                .more-button {
                  font-size: 3rem;
                  max-height: 80px;
                  transition: max-height 1s;
                  .arrow-right-white {
                    transition: transform 1s;
                    transform: rotate(28deg);
                  }
                  /* position: relative;
                  &:after {
                    content: '';
                    background-image: url('${process.env.PUBLIC_URL}/images/common/arrow-right-white.png');
                    width: 30px;
                    height: 20px;
                    top: 0;
                    right: 0;
                    position: absolute;
                    background-color: #000;
                  } */
                }
              }
            }

            .svg-wrap {
              /* left: initial;
              right: 20px; */
              &.show {
              }
              @keyframes showani {
                0% {
                  transform: scale(1);
                }
                50% {
                  transform: scale(1.2);
                }
                100% {
                  transform: scale(1);
                }
              }
            }
          }

          .coin-ibt-section {
          }
          .ctc-wallet-section {
          }
          .bb-market-section {
          }
        }
        .main-content-navi-container {
          display: none;
          &.hide {
          }

          .main-content-navi {
            .navi-text-area {
              .navi-text {
                .navi-text-span {
                }

                &.active {
                }
              }
            }

            .navi-bottom-bar {
              .active-bar {
              }
            }
          }
        }

        .detail-view-container {
          &.up {
            top: 50%;
          }

          .detail-section {
            .detail-section-inner {
              padding-top: 15px;
              height: 94%;
              .detail-left {
                display: none;
                .detail-subtitle {
                }
                .detail-section-title {
                }
              }
              .detail-right {
                display: flex;
                flex-direction: column-reverse;

                .detail-right-top {
                  border-bottom-width: 0px;
                  margin: 0;
                  padding: 0;
                  /* height: 42%; */
                  height: 50%;

                  .detail-main-desc {
                    display: none;
                  }
                  .box-list {
                    padding: 0 10px;
                    height: 67%;
                    .box-item {
                      flex: 1;
                      padding: 5px;
                      &:not(:last-of-type) {
                        margin-right: 5px;
                      }

                      .box-image-wrap {
                        margin: 0;
                        .box-image {
                          /* width: 30px; */
                          height: 30px;
                        }
                      }
                      .box-title {
                      }
                      .box-content {
                        flex: 1;
                        &:not(.content-only) {
                          display: none;
                        }
                      }
                    }
                  }
                }
                .detail-right-bottom {
                  flex: 1;
                  padding: 0 20px;
                  margin-bottom: 5px;

                  .detail-right-bottom-inner {
                    height: 100%;
                    border-bottom-width: 1px;
                    border-color: #dddddd;

                    .bottom-title-area {
                      .bottom-title {
                        font-size: 3rem;
                      }
                      .go-to-site {
                        display: none;
                      }
                    }

                    .detail-bottom-content {
                      font-size: 2rem;
                      /* padding: 0 0 20px 0; */
                      padding: 0;
                      /* border-bottom-width: 1px;
                    border-color: #dddddd; */
                      color: ${colors.black};
                    }
                  }
                }
              }
            }

            &:nth-of-type(1) {
              .detail-right {
                .detail-right-bottom {
                  .detail-right-bottom-inner {
                    .detail-bottom-content {
                      line-height: 1.8;
                    }
                  }
                }
              }
            }

            &:nth-of-type(3) {
              .detail-right {
                .detail-right-bottom {
                  .detail-right-bottom-inner {
                    .bottom-title-area > .bottom-title {
                      line-height: 1.1;
                    }
                    .detail-bottom-content {
                      line-height: 1.2;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default BusinessAreaSection;
