const lang = {
  // tests
  hello: '안녕',
  obj: {
    his: '히스',
    change: 'my name is {{ name }}',
  },

  // real
  main: {
    subtitle: '우리는 세상에 없는 혁신적인 서비스를 창조하며 더 나은 가치를 만들어갑니다.',
  },
  whoWeAreNav: {
    nav1: '개요 및 연혁',
    nav2: '핵심가치',
    nav3: '팀원',
    nav4: '기부금',
  },
  whoWeAre: {
    section1: {
      sectionTitle: `
        국내 블록체인 기술과 <br />
        비접촉 스마트 기술 대중화에 <br />
        기여하고 있습니다.
      `,
      moreList: {
        history: `
        국내 최대의
        블록체인 시스템 개발을하고 있으며 새로운 블록 체인 기반 비접촉 스마트 기술을 개발하고 있습니다.
        `,
        aim: `
        글로벌 시장에서
        최고를 목표로 D App 선도기업으로서 최상의 서비스를 제공하며 기술개발과 책임을 다합니다.
        `,
        system: `
        끊임 없는 혁신으로
        더 편리하고 모두가 믿고 신뢰할 수 있는 개발 플랫폼의 완전한 시스템을 제공합니다.
        `,
      },
    },
    section2: {
      leftContent: `
        <strong>한스바이오텍</strong>은 2016년말부터, 새로운 블록체인시스템을 준비 하기 위해
        블록 체인 기반 비접촉 스마트 기술을 개발하고 있습니다.
        대부분의 암호화 시장은 공급 업체 및 소비자와 밀접하게 거래되고 있으며
        사용자는 블록체인에 대한 투자에 강한 관심을 가지고 있지만
        경험이 거의 없습니다.
    
        국내 블록 체인 시장은 불안정하지만 2020년 3월 금융 특별법 개정으로 투명하고
        합법적인 시장을 만들 수 있었습니다. 2019년 초부터 CYBERTRONCHAIN은 
        한스바이오텍의 스마트결제 시장의 비전이었습니다.
    
        현재 블록 체인 시장은 빠르게 발전하고 있으며, 한스 바이오텍은 블록체인이
        일상의 서비스를 변화시킬 것이라고 믿습니다. 전 세계적으로 많은 온라인 플랫폼들이
        존재하고 있지만 일상 생활에서 겪는 불편함과 높은 수수료로 사용자들은 만족하지
        못하고 있습니다. 한스바이오텍은 온라인과 오프라인의 통합시장에 더 나은 솔루션을
        제공하며, 새로운 결제 시스템으로 전 세계를 탐험할 수 있는 기회로 인식하고 있습니다.
      `,
      leftContentMobile: `
        <strong>한스바이오텍</strong>은
        2016년말부터, 새로운 암호화폐시스템을 준비 하기 위해 블록 체인 기반 비접촉 스마트 기술을 개발하고 있습니다. 대부분의 암호화 시장은 공급 업체 및 소비자와 밀접하게 거래되고 있으며 사용자는 암호 화폐에 대한 투자에 강한 관심을 가지고 있지만 경험이 거의 없습니다. 국내 블록 체인 시장은 불안정하지만 2020년 3월 금융 특별법 개정으로 투명하고 합법적인 시장을 만들 수 있었습니다.
      `,
      roadmap: {
        t1: {
          date: '2019년 7월',
          content: '신원 인증이 가능한 개인지갑 (CTC 수수료)',
        },
        t2: {
          date: '2020년 5월',
          content: 'Barry Barries P2P 트레이딩 쇼핑몰 (CTC 수수료)',
        },
        t3: {
          date: '2020년 9월',
          content: '결제 키오스크 개발 (CTC 수수료)',
        },
        t4: {
          date: '2020년 10월',
          content: '유럽 법인 설립',
        },
        t5: {
          date: '2020년 11월',
          content: `
            코인 IBT 거래소 오픈 <br />
            스마트 마스크 키오스크 개발 (CTC 수수료)
          `,
        },
        t6: {
          date: '2021년 9월',
          content: `
          코인 ibt거래소 종료
          `,
        },
        t7: {
          date: '2021년 10월',
          content: `
          타지키스탄 산업기술 자원부 와 MOU 체결
          `,
        },
        t8: {
          date: '2021년 10월',
          content: `
          타지기스탄 소먼항공 과 직항을 위한 mou 체결
          `,
        },
        t9: {
          date: '2022년 5월',
          content: `
          수출입 무역업 등록
          `,
        },
        t10: {
          date: '2022년 6월',
          content: `
          세계수출입 박람회 타지키스탄 부스 개최
          `,
        },
      },
      sectionRight: {
        hansWrap: '2016년부터 암호화폐 시스템 개발!',
        bioWrap: '스마트결제 시장의 비전!',
        techWrap: '블록체인의 생활화!',
      },
    },
    section3: {
      missionTitle: '모두에게 제공하여 삶의 질 향상을 선도하는 생활화시스템',
      missionContent: `
        한스바이오텍의 인프라는 국내 블록체인 기술과 가상자산 실사용의 대중화를 앞당기고 있습니다. 우리는
        급변하는 미래사회에 능동적으로 대처하고, 새로운 시각에서 통찰력과 융통성 있는 사고와 발상으로 가치 있는
        아이디어와 산출물을 생산하는 능력을 기르고자 끊임없이 노력하고 있습니다.
      `,
      fourMission: {
        m1: {
          title: '혁신성',
          content: '새로운 가치를 창출하고, 목표달성을 위해 끝까지 최선을 다합니다.',
        },
        m2: {
          title: '원칙준수',
          content: '원칙을 준수하며 신념과 신뢰의 가치를 중시합니다.',
        },
        m3: {
          title: '유연성',
          content: '다양한 시각으로 환경 변화를 주시하고 새로운 트렌드를 이끌어갑니다.',
        },
        m4: {
          title: '세계 최고',
          content: '세계 시장을 주도하기 위해 끊임없이 노력해 최고의 전문성과 경쟁력을 제공합니다.',
        },
      },
    },
    section4: {},
  },
  businessArea: {
    moveSite: '사이트로 이동',
    text01: `
      끊임없는 혁신으로 우리 삶에 지속 가능한 <br />
      새로운 서비스를 만들다
    `,
    coinIBT: {
      subtitle: '안전한 거래 플랫폼',
      desc: `
        Coin ibt는 2021년9월 24일 
        이후 가상자산사업자의 자금세탁 방지법 
        (특금법)을 준수하여 영업을 중단 하였습니다.
        이후 (특정금융정보법 시행령) 에 의거하여 법 
        적용범위를 준수하여 다시 찾아뵙겠습니다. 
        Coin ibt 를 사랑해 주셔서 감사합니다. 
      `,

      detail: {
        mainDesc:
          '2021년 9월 24일 ISMS 인증허가이후 영업을. 종료합나다.',
        bottomTitle: '안전한 거래 플랫폼, COIN IBT 영업종료',
        bottomContent: `
          2020년 10월 오픈한 Coin IBT 거래소는 비트코인, 이더리움 등의 암호화폐를 매수/매도할 수 있는 플랫폼 입니다.
          전 세계인이 사용하는 안전한 거래소로서 고객 자산의 안전을 최우선으로 합니다.
        `,
        boxContents: {
          t1: {
            title: '신뢰하다',
            content: '투명한 정책으로 모두가 신뢰할 수 있는 금융환경을 구축합니다.',
          },
          t2: {
            title: '전문적이다',
            content: '국내외 최고의 전문가들이 다양한경험과 기술을 공유합니다.',
          },
          t3: {
            title: '혁신적이다',
            content: '믿고 투자할 수 있는 코인의 채굴 안정적인 투자를 제공합니다.',
          },
        },
      },
    },
    ctcWallet: {
      subtitle: '안전한 가상자산 지갑',
      desc: `
      Ctc월렛은 2016년 개발을 시작 하여 2019년 
      7월 에 전세계에 선보였습니다. 
      CTC월렛은 솔리디티 언어와 웝3.0 의기술로 
      개발되었으며 세계최초 개인 실명화 주소를 탑재 하였습니다.      
      `,
      detail: {
        mainDesc:
          'CTC WALLET은 가장 중요한 가상자산 보안이나 실명 지원 및 POS 시스템을 제공합니다. D앱의 기술 형태와 스마트 계약을 활용한 블록체인기술로 빠른 속도와 확장성으로 상호운용으로 진화하고 있으며, 비대면 스마트결제 시스템을 통한 결제와 지불, 교환 등의 원활한 진행을위해 웝 3.0의 기술을 적용하였습니다.',
        bottomTitle: '한스바이오텍은 CTC월렛서비스를 초기개발 주체 HANSBLOCK.ou로 관리 시스템을 변경하였습니다.',
        bottomContent:
          '한스바이오텍을 사랑해주셔서 감사합니다.',
        boxContents: {
          t1: {
            title: '다중 보안으로 보호',
            content: '',
          },
          t2: {
            title: '앱 및 웹에서 빠른 결제',
            content: '',
          },
          t3: {
            title: '간편한 정보 관리',
            content: '',
          },
        },
      },
    },
    bbMarket: {
      subtitle: '안전한 거래 플랫폼',
      desc: `
        CTC 지갑이 연동되어 거래가 바로 가능합니다.
        구매자를 보호하기 위해 안전 구매기능을 제공하여
        판매자의 상품을 안심하고 거래할 수 있습니다.
      `,
      detail: {
        mainDesc:
          '바리바리마켓은 플래폼을 통해 판매하는 사람과 구매를 원하는 사람이 직접적으로 대면하지 않고도 상품이나 서비스를 제공하는 (혹은 제공받는) P2P 거래 플랫폼입니다. 실명인증을 통해 상품 및 서비스를 거래하는 판매자 및 소비자 네트워크이며, 실생활에 밀접하고 사용 가능합니다. 투명성 있는 시스템으로 세금을 정산하고 영수증 처리도 가능하며, 판매 또는 구매 뿐만 아니라 자영업자 마케팅, 구인 등이 가능해 ‘동네전단지’역할도 지원합니다.',
        bottomTitle: 'Coin IBT에서 발행하고 관리하는 E&#x02011TP3,E&#x02011MC로 거래가 가능한 플랫폼',
        bottomContent:
          'E-TP3, E-MC는 베리베리마켓에서만 사용하는 가상화폐 입니다. 이 화폐들은거래가 끝난 후 CTC 월렛에서 TP3, MC등 리얼 코인으로 환전이 가능하며, 환전이 끝난 코인은 IBT 자사 코인거래소에서 현금으로도 환전이 가능합니다.',
        boxContents: {
          t1: {
            title: '',
            content: '',
          },
          t2: {
            title: '',
            content: '',
          },
          t3: {
            title: '',
            content: '',
          },
        },
      },
    },
  },
  newsRoom: {
    sectionTitle: '한스바이오텍<span className="pc-only">에서 전하는</span> 소식과 이야기를 만나보세요.',
    mainNewsContent: `
      블록체인 기반 비접촉식 스마트결제 시스템을 개발·관리하는 (주)한스바이오텍이 한마음스마트와 손잡고 무인
      키오스크(무인 정보단말기)의 프로그램을 응용한 자산 결제 프로그램을 개발해 상업화에 나선다. 무인
      키오스크는 쇼핑센터와 같은 대형 상업시설과 사회곳곳에 적용되고 있다. <br />
      한스바이오텍은 무인 키오스크의 프로그램을 응용해 가상자산 결제 프로그램을 개발해 상업용 시장에 공급할
      계획이다. 한스바이오텍은 P2P 기반 분산원장 등과 같은 송금 및 가상자산 중신의 기술 서비스를 제공 및
      개선하고 기술의 고도화를 통화 스마트 계약 자동 지급 결제 시스템을 구현하고 있다.
    `,
    directionShow: '바로 보기',
    newsList: {
      a1: {
        title: '비즈니스 4차 산업 혁명의 핵심 기술을 이끌어내는 암호화폐, 한스바이오텍',
        hashtags: {
          a1: '블록체인',
          a2: '무인키오스크',
          a3: '스마트결제시스템',
          a4: '한스바이오텍',
          a5: '한마음스마트',
        },
      },
      a2: {
        title: '언택트 시대, 가상화폐로 안전하고 똑똑하게 결제하는 방법',
        hashtags: {
          a1: '블록체인',
          a2: '무인키오스크',
          a3: '스마트결제시스템',
          a4: '한스바이오텍',
          a5: '한마음스마트',
        },
      },
    },
  },
  lastSection: {
    companyAddress: '서울특별시 금천구 가산디지털1로 168 우림라이온스밸리 B동',
  },
  footer: {
    sitemap: '사이트맵',
    partnership: '제휴제안',
    privacyPolicy: '개인정보처리방침',
    termsOfService: '이용약관',
    companyRegistrationNumber: '사업자등록번호',
  },
  common: {
    learnMore: '더 알아보기',
    yourName: '이름',
    emailAddress: '이메일 주소',
    pleaseEnter: '내용을 입력해 주세요.',
    address: '주소',
    customerCenter: '고객센터',
    ceo: '대표이사',
    ceoName: '김라이',
    oneHeartSmart: '한마음스마트',
  },
};

export default lang;
