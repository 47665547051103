import { colors, mixins, sizes } from '@/interfaces/constants';
import { css } from '@emotion/react';
import SVGEarth from '@/components/SVG/SVGEarth';
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useRootState } from '@/store/RootStore';
import React from 'react';
import { useTranslation } from 'react-i18next';

type NavProps = {
  isTopSection: boolean;
};
type NavActiveColor = '#0091db' | '#eb5f01' | '#5cc6ea' | '#3ac769';
function Nav({ isTopSection }: NavProps) {
  const { fullpageState, fullpageApi } = useRootState();
  const [chooseLangOpened, setChooseLangOpened] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { i18n } = useTranslation();

  const [navList, setNavList] = useState([
    {
      text: 'WHO WE ARE',
      active: false,
    },
    {
      text: 'BUSINESS AREA',
      active: false,
    },
    {
      text: 'NEWS ROOM',
      active: false,
    },
    {
      text: 'CONTACT',
      active: false,
    },
  ]);
  const navActiveColorList: NavActiveColor[] = ['#0091db', '#eb5f01', '#5cc6ea', '#3ac769'];
  const [navActiveColor, setNavActiveColor] = useState<NavActiveColor>('#0091db');

  useEffect(() => {
    const currentActiveSection = fullpageApi?.getActiveSection().index;
    setNavList(
      navList.map((item, index) => {
        if (currentActiveSection - 1 === index) {
          item.active = true;
        } else {
          item.active = false;
        }
        return item;
      }),
    );
  }, [fullpageState]);

  useEffect(() => {
    setNavActiveColor(navActiveColorList[navList.findIndex((item) => item.active === true)]);
  }, [navList]);

  useEffect(() => {
    function navOpenedListener(ev: MouseEvent) {
      const eventPath = ev.composedPath();

      const isClickNavOpenArea = eventPath.find((path) => {
        if (!(path instanceof HTMLElement)) return;
        return path.classList?.contains('nav-open-area');
      });
      if (!isClickNavOpenArea && isNavOpen) {
        // close nav
        setIsNavOpen(false);
      }
    }
    if (isNavOpen) {
      window.addEventListener('click', navOpenedListener);
    } else {
      window.removeEventListener('click', navOpenedListener);
    }

    return () => {
      window.removeEventListener('click', navOpenedListener);
    };
  }, [isNavOpen]);

  function toggleChooseLang() {
    setChooseLangOpened(!chooseLangOpened);
  }

  return (
    <>
      <nav
        css={css`
          ${navCss}
          /* 첫번째 Section이 아닐 경우 적용됨 */
        ${isTopSection === false &&
          `
          background-color: ${colors.white};
          color: ${colors.gray['001']};
          // --active-color: ${colors.blue['001']};
          box-shadow: 0px 0px 12px 1px ${mixins.boxShadowColor};
          // --logo-white-opacity: 0;
          // --logo-colorful-opacity: 1;
          --choose-lang-background-color: rgba(0,0,0,0.3);
          .choose-lang {
            border: none;
          }


          ////////////
          // IE 용도
          ////////////
          .nav-container .nav-right-area .nav-menus .menu-item {
            color: ${colors.gray['001']};
            border-color: ${colors.gray['001']};

            &.active {
              color: ${navActiveColor};
              border-color: ${navActiveColor};
            }
          }

          .nav-container .nav-logo-wrap {
            .logo-white {
              opacity: 0;
            }
            .logo-colorful {
              opacity: 1;
            }
          }

          .nav-container .nav-right-area .nav-choose-lang-area .choose-lang {
            background-color: rgba(0,0,0,0.3);
          }
        `}
        `}
      >
        <div className="nav-container">
          <div
            className="nav-logo-wrap"
            onClick={() => {
              fullpageApi?.moveTo(2, 0);
              fullpageApi?.moveTo(1);
            }}
          >
            <img
              // src="/images/nav/logo-white.png"
              src={process.env.PUBLIC_URL + '/images/nav/logo-white.svg'}
              alt="nav-logo"
              className="logo-white"
            />
            <img
              // src="/images/nav/logo-colorful.png"
              // src={process.env.PUBLIC_URL + '/images/nav/logo-colorful.png'}
              src={process.env.PUBLIC_URL + '/images/nav/logo-colorful.svg'}
              alt="nav-logo"
              className="logo-colorful"
            />
          </div>
          <div className="nav-right-area">
            <div className="nav-menus">
              {navList.map((item, index) => (
                <div
                  className={classNames({
                    'menu-item': true,
                    active: item.active,
                  })}
                  key={index + item.text + item.active}
                  onClick={() => {
                    fullpageApi?.moveTo(2, 0);
                    fullpageApi?.moveTo(index + 2);
                  }}
                >
                  {item.text}
                </div>
              ))}
              {/* <div className="menu-item active">WHO WE ARE</div>
            <div className="menu-item">BUSINESS AREA</div>
            <div className="menu-item">NEWS ROOM</div>
            <div className="menu-item">CONTACT</div> */}
            </div>
            <div className="nav-choose-lang-area">
              <div className="svg-wrap" onClick={toggleChooseLang}>
                <SVGEarth size={16} fill={isTopSection ? '#fff' : '#000'} className="svg-earth" />
              </div>
              {chooseLangOpened && (
                <div className="choose-lang">
                  {['kr', 'en'].map((item, index) => (
                    <div
                      className={classNames({
                        'choose-lang-item': true,
                        active: item === 'kr' ? 'ko' === i18n.language : item === i18n.language,
                      })}
                      key={JSON.stringify(item) + index}
                      onClick={() => {
                        const changeLang = item === 'kr' ? 'ko' : item;
                        i18n.changeLanguage(changeLang);
                      }}
                    >
                      {item.toUpperCase()}
                    </div>
                  ))}
                  {/* <div className="choose-lang-item active">KR</div>
                  <div className="choose-lang-item">EN</div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      <nav
        css={navMobileCss}
        className={classNames({
          isNotTopSection: !isTopSection,
        })}
      >
        <div
          className={classNames({
            'nav-mobile': true,
            'nav-open': isNavOpen,
          })}
        >
          {/* <div
            className="hamburger-button-wrap"
            onClick={() => setIsNavOpen(!isNavOpen)}
          >
            <div className="hamburger-bar1"></div>
            <div className="hamburger-bar2"></div>
            <div className="hamburger-bar3"></div>
          </div> */}
          <HamburgerButton
            isNavOpen={isNavOpen}
            isTopSection={isTopSection}
            onClickHamburgerButton={() => setIsNavOpen(true)}
          />
          <div
            className="nav-logo-wrap"
            onClick={() => {
              fullpageApi?.moveTo(2, 0);
              fullpageApi?.moveTo(1);
            }}
          >
            <img className="logo-white" src={process.env.PUBLIC_URL + '/images/nav/logo-white.svg'} alt="nav-logo" />
            <img
              className="logo-colorful"
              src={process.env.PUBLIC_URL + '/images/nav/logo-colorful.svg'}
              alt="nav-logo"
            />
          </div>
          <div className="empty" style={{ width: '35px' }}></div>

          <div
            className={classNames({
              'nav-open-area': true,
              'is-open': isNavOpen,
            })}
          >
            <div className="nav-open-area-inner">
              <div className="hamburger-wrap">
                <HamburgerButton
                  isNavOpen={isNavOpen}
                  isTopSection={isTopSection}
                  onClickHamburgerButton={() => setIsNavOpen(false)}
                />
              </div>
              <div className="nav-list">
                {navList.map((item, index) => (
                  <div className="nav-list-item" key={JSON.stringify(item)}>
                    <span
                      className="nav-list-item-text"
                      onClick={() => {
                        fullpageApi?.moveTo(2, 0);
                        fullpageApi?.moveTo(index + 2);
                        setIsNavOpen(false);
                      }}
                    >
                      {item.text}
                    </span>
                  </div>
                ))}
              </div>
              <div className="choose-lang-area">
                <div className="choose-lang">
                  <span className="language-text">LANGUAGE</span>
                  <select
                    id="lang-select"
                    className="lang-select"
                    value={i18n.language}
                    onChange={(event) => {
                      i18n.changeLanguage(event.target.value);
                    }}
                  >
                    {[
                      {
                        value: 'ko',
                        text: 'Kor',
                      },
                      {
                        value: 'en',
                        text: 'Eng',
                      },
                    ].map((item, index) => (
                      <option value={item.value} key={index + JSON.stringify(item)}>
                        {item.text}
                      </option>
                    ))}
                    {/* <option value="ko">Kor</option>
                    <option value="en">Eng</option> */}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

type HamburgerButtonProps = {
  isNavOpen: boolean;
  isTopSection: boolean;
  onClickHamburgerButton: () => void;
};
function HamburgerButton(props: HamburgerButtonProps) {
  return (
    <div
      className={classNames({
        change: props.isNavOpen,
        'not-top-section': !props.isTopSection,
      })}
      css={hamburgerButtonCss}
      onClick={props.onClickHamburgerButton}
    >
      <div className="hamburger-bar1"></div>
      <div className="hamburger-bar2"></div>
      <div className="hamburger-bar3"></div>
    </div>
  );
}

const hamburgerButtonCss = css`
  .hamburger-bar1,
  .hamburger-bar2,
  .hamburger-bar3 {
    width: 35px;
    height: 3px;
    background-color: #fff;
    margin: 6px 0;
    transition: background-color 1s ease-out, transform 400ms, opacity 400ms;
  }

  &.not-top-section {
    .hamburger-bar1,
    .hamburger-bar2,
    .hamburger-bar3 {
      background-color: #000;
    }
  }

  &.change {
    .hamburger-bar1,
    .hamburger-bar2,
    .hamburger-bar3 {
      background-color: #000;
    }
    .hamburger-bar2 {
      opacity: 0;
    }

    .hamburger-bar1 {
      transform: rotate(-45deg) translate(-6px, 7px);
    }
    .hamburger-bar3 {
      transform: rotate(45deg) translate(-6px, -7px);
    }
  }
`;

const navCss = css`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: ${sizes.navHeight}px;
  /* overflow: hidden; */

  background-color: transparent;
  color: ${colors.white};
  --active-color: ${colors.green['001']};
  --logo-white-opacity: 1;
  --logo-colorful-opacity: 0;
  --choose-lang-background-color: rgba(255, 255, 255, 0.5);

  transition: background-color 1s, color 1s;
  .nav-container {
    /* width: ${sizes.navWidth}px; */
    min-width: 1000px;
    margin: 0 auto;

    font-size: 1.5rem;

    padding: 0 103px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-logo-wrap {
      position: relative;
      cursor: pointer;
      width: 160px;

      .logo-white,
      .logo-colorful {
        transition: opacity 2s ease-out;
      }
      .logo-white {
        /* opacity: var(--logo-white-opacity); */
        opacity: 1;
      }
      .logo-colorful {
        position: absolute;
        top: 0;
        left: 0;
        /* opacity: var(--logo-colorful-opacity); */
        opacity: 0;
      }
    }

    .nav-right-area {
      font-family: 'Poppins';
      display: flex;

      .nav-menus {
        display: flex;
        font-weight: 700;
        .menu-item {
          cursor: pointer;
          padding-bottom: 5px;
          transition: color 1s, border-color 1s;

          &:not(:last-of-type) {
            margin-right: 34px;
          }

          &.active {
            color: var(--active-color);
            border-color: var(--active-color);
            border-bottom-width: 2px;
          }
        }
      }
      .nav-choose-lang-area {
        margin-left: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;
        position: relative;

        .choose-lang-icon {
          cursor: pointer;
        }
        .svg-wrap {
          display: flex;
          justify-content: center;
          align-items: center;

          .svg-earth {
            transition: fill 1s;
            cursor: pointer;
          }
        }

        .choose-lang {
          width: 45px;
          position: absolute;
          top: 0;
          right: -50px;

          /* background-color: var(--choose-lang-background-color); */
          background-color: rgba(255, 255, 255, 0.5);
          border-color: #fff;
          border-width: 1px;
          border-radius: 15%;
          color: #fff;
          text-align: center;

          .choose-lang-item {
            cursor: pointer;

            &:hover,
            &.active {
              color: black;
            }
          }
        }
      }
    }
  }

  @media (max-width: ${sizes.maxWidth}px) {
    display: none;
  }
`;

const navMobileCss = css`
  display: none;
  @media (max-width: ${sizes.maxWidth}px) {
    display: initial;
  }

  &.isNotTopSection {
    background-color: #fff;
    border-color: #6c6c6c;
    border-bottom-width: 1px;
    .nav-mobile {
      .nav-logo-wrap {
        .logo-white {
          opacity: 0;
        }
        .logo-colorful {
          opacity: 1;
        }
      }
    }
  }

  position: fixed;
  z-index: 100;
  width: 100%;
  height: ${sizes.navHeight}px;
  .nav-mobile {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    height: 100%;

    .nav-logo-wrap {
      position: relative;
      cursor: pointer;
      width: 160px;

      .logo-white,
      .logo-colorful {
        transition: opacity 2s ease-out;
      }
      .logo-white {
        opacity: 1;
      }
      .logo-colorful {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    .nav-open-area {
      position: fixed;
      top: 0;
      left: 0;
      width: 87%;
      height: 100%;
      background-color: #fff;
      transform: translateX(-100%);
      transition: transform 0.5s, box-shadow 1s;

      &.is-open {
        transform: translateX(0);
        box-shadow: 8.4px 10px 46px 0 rgba(15, 15, 15, 0.54);
      }

      .nav-open-area-inner {
        padding: 10px 20px 25px;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        height: 100%;

        .hamburger-wrap {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 100px;
          padding-right: 20px;
        }

        .nav-list {
          flex: 1;
          .nav-list-item {
            font-size: 3rem;
            padding-bottom: 0.25rem;
            border-color: #e6e6e6;
            border-bottom-width: 1px;
            color: #000;
            font-family: 'Poppins';
            font-weight: 700;

            .nav-list-item-text {
              cursor: pointer;
            }

            &:not(:last-of-type) {
              margin-bottom: 20px;
            }
          }
        }

        .choose-lang-area {
          display: flex;
          justify-content: flex-end;
          padding-right: 30px;
          .choose-lang {
            .language-text {
              font-family: 'Poppins';
              font-size: 3rem;
              margin-right: 20px;
            }

            .lang-select {
              font-size: 3rem;
              font-weight: 700;
              font-family: 'Poppins';
              outline: none;
              text-transform: uppercase;
              padding-left: 9px;
            }
          }
        }
      }
    }
  }
`;

export default Nav;
