import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type LinkWrapProps = {
  link: string;
  className?: string;
  cursorPointer?: boolean;
};
function LinkWrap({
  link,
  className,
  cursorPointer,
  children,
}: PropsWithChildren<LinkWrapProps>) {
  if (link === '') {
    return (
      <span
        className={className}
        style={{
          cursor: cursorPointer ? 'pointer' : 'text',
        }}
      >
        {children}
      </span>
    );
  }
  if (link.startsWith('http')) {
    // External Link
    return (
      <a href={link} target="_blank" rel="noreferrer" className={className}>
        {children}
      </a>
    );
  }
  return (
    <Link to={link} className={className}>
      {children}
    </Link>
  );
}

export default LinkWrap;
