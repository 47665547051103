import { colors, sizes } from '@/interfaces/constants';
import { getUuid } from '@/utils';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import Truncate from 'react-truncate';

function NewsRoom() {
  const { t } = useTranslation();
  const newsList = [
    {
      thumbnail: '/images/news-room/img2.png',
      title: t('newsRoom.newsList.a1.title'),
      hashtags: [
        t('newsRoom.newsList.a1.hashtags.a1'),
        t('newsRoom.newsList.a1.hashtags.a2'),
        t('newsRoom.newsList.a1.hashtags.a3'),
        t('newsRoom.newsList.a1.hashtags.a4'),
        t('newsRoom.newsList.a1.hashtags.a5'),
      ],
      date: '2021.05.02',
      link: 'https://blog.naver.com/71423/222166784967',
    },
    {
      thumbnail: '/images/news-room/img3.png',
      title: t('newsRoom.newsList.a2.title'),
      hashtags: [
        t('newsRoom.newsList.a2.hashtags.a1'),
        t('newsRoom.newsList.a2.hashtags.a2'),
        t('newsRoom.newsList.a2.hashtags.a3'),
        t('newsRoom.newsList.a2.hashtags.a4'),
        t('newsRoom.newsList.a2.hashtags.a5'),
      ],
      date: '2021.05.02',
      link: 'https://tv.naver.com/v/18875106',
    },
  ];

  return (
    <div css={newsRoomCss} className="section-page">
      <div className="news-room">
        <div className="section-name">NEWS ROOM</div>
        <div className="section-title" dangerouslySetInnerHTML={{ __html: t('newsRoom.sectionTitle') }}></div>

        <div className="main-news">
          <div className="main-news-img-wrap">
            <img
              src={process.env.PUBLIC_URL + '/images/news-room/img1.png'}
              alt="news-banner"
              className="main-news-img"
            />
            <a href="https://tv.naver.com/v/17290608" target="_blank" className="more-button mobile-only">
              <span>{t('common.learnMore')}</span>
              <img className="arrow-img" src={process.env.PUBLIC_URL + '/images/common/arrow-right-white.png'} alt="" />
            </a>
          </div>
          <div className="main-news-content-area">
            <div className="news-content">
              <Truncate ellipsis="..." lines={3}>
                {t('newsRoom.mainNewsContent')}
              </Truncate>
            </div>
            <a href="https://tv.naver.com/v/17290608" target="_blank" className="more-button pc-only">
              <span>{t('common.learnMore')}</span>
              <img className="arrow-img" src={process.env.PUBLIC_URL + '/images/common/arrow-right-white.png'} alt="" />
            </a>
          </div>
        </div>
        <div className="news-list">
          {newsList.map((item) => (
            <div className="news-list-item" key={JSON.stringify(item)}>
              <div className="thumbnail-area">
                <img src={process.env.PUBLIC_URL + item.thumbnail} alt="news-thumbnail" />
              </div>
              <div className="title-content-area">
                <div className="list-item-title">{item.title}</div>
                <a href={item.link} target="_blank" className="direct-show">
                  {t('newsRoom.directionShow')}
                  <img src={process.env.PUBLIC_URL + '/images/common/share.png'} alt="share" className="share-img" />
                </a>
                <div className="hashtags">
                  {item.hashtags.slice(2).map((hashtag) => (
                    <div className="hashtag" key={getUuid()}>
                      #{hashtag}
                    </div>
                  ))}
                </div>
              </div>
              <div className="date-area">{item.date}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const newsRoomCss = css`
  height: 100%;
  background-image: url('${process.env.PUBLIC_URL}/images/news-room/bg.png');
  background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;

  .mobile-only {
    display: none;
  }

  .news-room {
    height: 100%;
    padding: 53px 0;
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > .section-name {
      font-family: 'Poppins';
      font-weight: 700;
      font-size: 1.8rem;
      margin-bottom: 2.1rem;
    }
    > .section-title {
      font-weight: 700;
      font-size: 3rem;
      margin-bottom: 3.6rem;
    }

    .main-news {
      display: flex;
      justify-content: space-between;
      max-width: 1000px;
      padding: 0 21px;
      padding-bottom: 30px;
      flex: 1;
      max-height: 450px;

      .main-news-img-wrap {
        width: 40%;
        .main-news-img {
          width: 100%;
        }
      }
      .main-news-content-area {
        width: 60%;
        padding-left: 25px;
        .news-content {
          font-size: 1.5rem;
          line-height: 2.5rem;
          margin-bottom: 3.4rem;
          font-weight: 300;
        }
        .more-button {
          font-size: 1.6rem;
          font-weight: 700;
          background-color: #5cc6ea;
          padding: 10px 15px;
          display: inline-flex;
          align-items: center;

          .arrow-img {
            width: 30px;
            margin-left: 30px;
            transform: rotate(30deg);
          }
        }
      }
    }

    .news-list {
      max-width: 950px;
      .news-list-item {
        padding: 27px 5px 27px 48px;
        border-color: #717171;
        border-top-width: 1px;
        display: flex;
        color: ${colors.white};

        .thumbnail-area {
          width: 20%;
          img {
            width: 100%;
          }
        }

        .title-content-area {
          width: 70%;
          padding: 0 37px;
          .list-item-title {
            font-size: 1.8rem;
            font-weight: 700;
            margin-bottom: 15px;
          }
          .direct-show {
            font-size: 1.5rem;
            display: inline-flex;
            margin-bottom: 33px;
            align-items: center;
            cursor: pointer;
            .share-img {
              width: 21px;
              margin-left: 7px;
            }
          }
          .hashtags {
            color: #a5a5a5;
            font-size: 1.5rem;
            display: flex;
            .hashtag {
              margin-right: 1rem;
            }
          }
        }

        .date-area {
          width: 10%;
          font-size: 1.5rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
  }

  @media (max-width: ${sizes.maxWidth}px) {
    .pc-only {
      display: none !important;
    }
    .mobile-only {
      display: initial;
    }

    .news-room {
      padding: 20px;
      justify-content: flex-start;
      > .section-name {
        font-size: 4rem;
        color: #5cc6ea;
        width: 100%;
      }
      > .section-title {
        font-size: 2.4rem;
        width: 100%;
      }

      .main-news {
        padding-bottom: 20px;
        flex-direction: column;
        flex: 0;

        .main-news-img-wrap {
          width: 85%;
          margin: 0 auto;
          margin-bottom: 15px;
          position: relative;
          .main-news-img {
          }
          .more-button {
            font-size: 2.4rem;
            font-weight: 700;
            background-color: #5cc6ea;
            padding: 5px 11px;
            display: flex;
            align-items: center;

            position: absolute;
            right: 0;
            bottom: 0;

            .arrow-img {
              width: 25px;
              margin-left: 15px;
              transform: rotate(30deg);
            }
          }
        }
        .main-news-content-area {
          width: 100%;
          padding: 0;
          .news-content {
            margin: 0;
            font-size: 2rem;
          }
          .more-button {
          }
        }
      }

      .news-list {
        /* padding: 0 20px; */
        .news-list-item {
          padding: 10px;
          .thumbnail-area {
            width: 43%;
            img {
            }
          }

          .title-content-area {
            width: initial;
            /* flex: 1; */
            padding: 0 0 0 15px;
            width: 66%;
            .list-item-title {
              margin-bottom: 10px;
              font-size: 1.7rem;
            }
            .direct-show {
              margin-bottom: 10px;
              .share-img {
                width: 12px;
              }
            }
            .hashtags {
              flex-wrap: wrap;
              .hashtag {
              }
            }
          }

          .date-area {
            display: none;
          }
        }
      }
    }
  }
`;

export default NewsRoom;
