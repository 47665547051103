import { colors, sizes } from '@/interfaces/constants';
import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';

export default function BasicModal(
  props: PropsWithChildren<{
    isShow: boolean;
  }>,
) {
  if (!props.isShow) {
    return null;
  }

  return (
    <div
      css={basicModalCss}
      className="basic-modal-container"
      onWheel={(e) => e.stopPropagation()}
      onTouchMoveCapture={(e) => e.stopPropagation()}
    >
      <div className="basic-modal-wrap">
        <div className="basic-modal">{props.children}</div>
      </div>
    </div>
  );
}

const basicModalCss = css`
  &.basic-modal-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    padding: calc(${sizes.navHeight}px + 30px) 30px;
    overflow: hidden;

    .basic-modal-wrap {
      height: 100%;
      overflow-y: auto;
      box-shadow: 1px 1px 13px 3px rgb(0 0 0 / 20%);
      background-color: ${colors.white};

      .basic-modal {
        height: 100%;
      }
    }
  }
`;
