import Footer from '@/components/Footer';
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal';
import TermsModal from '@/components/TermsModal';
import { colors, sizes } from '@/interfaces/constants';
import { css } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function LastSection() {
  const [messageText, setMessageText] = useState('');
  const [privacyPolicyModalIsShow, setPrivacyPolicyModalIsShow] = useState(false);
  const [termsModalIsShow, setTermsModalIsShow] = useState(false);
  const { t } = useTranslation();

  const siteList = [
    {
      imgSrc: '/images/last-section/cybertron.png',
    },
    {
      imgSrc: '/images/last-section/coin-ibt.png',
    },
    {
      imgSrc: '/images/last-section/bb-market.png',
    },
    {
      imgSrc: '/images/last-section/one-heart-smart.png',
    },
    {
      imgSrc: '/images/last-section/vixber.png',
    },
    {
      imgSrc: '/images/last-section/latoken.png',
    },
    {
      imgSrc: '/images/last-section/ether-flyer.png',
    },
    {
      imgSrc: '/images/last-section/coinranking.png',
    },
    {
      imgSrc: '/images/last-section/hansblock.png',
    },
  ];

  const infoList = [
    {
      iconSrc: '/images/last-section/map-icon.png',
      text: t('lastSection.companyAddress'),
    },
    // {
    //   iconSrc: '/images/last-section/tel-icon.png',
    //   text: '-',
    // },
    {
      iconSrc: '/images/last-section/mail-icon.png',
      text: 'cs@onefamilymall.com',
    },
  ];
  return (
    <div css={lastSectionCss} className="section-page">
      <div className="last-section">
        <div className="map-area">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                id="gmap_canvas"
                // src="https://maps.google.com/maps?q=%EC%84%9C%EC%9A%B8%20%EA%B8%88%EC%B2%9C%EA%B5%AC%20%EA%B0%80%EC%82%B0%EB%94%94%EC%A7%80%ED%84%B81%EB%A1%9C%20168&t=&z=13&ie=UTF8&iwloc=&output=embed"
                // src="https://maps.google.com/maps?q=%EC%84%9C%EC%9A%B8%20%EA%B8%88%EC%B2%9C%EA%B5%AC%20%EA%B0%80%EC%82%B0%EB%94%94%EC%A7%80%ED%84%B81%EB%A1%9C%20168&t=&z=17&ie=UTF8&iwloc=&output=embed"
                src="https://maps.google.com/maps?q=%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EA%B8%88%EC%B2%9C%EA%B5%AC%20%EA%B0%80%EC%82%B0%EB%94%94%EC%A7%80%ED%84%B81%EB%A1%9C%20168&t=&z=17&ie=UTF8&iwloc=&output=embed"
                scrolling="no"
                width="100%"
                height="100%"
              ></iframe>
              <a href="https://123movies-to.org"></a>
              <br />
              <a href="https://www.embedgooglemap.net">embedgooglemap.net</a>
            </div>
          </div>
        </div>
        <div className="middle-area">
          <div className="site-list-wrap">
            <div className="site-list">
              {siteList.map((item) => (
                <div className="site-list-item" key={JSON.stringify(item)}>
                  <img
                    src={process.env.PUBLIC_URL + item.imgSrc}
                    alt={item.imgSrc.split('/').pop()?.replace('.png', '')}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="send-message-container">
            <div className="send-message-area">
              <div className="send-message-title">Send Message</div>
              <div className="inputs-area">
                <input type="text" placeholder={t('common.yourName')} />
                <input type="text" placeholder={t('common.emailAddress')} />
              </div>
              <textarea
                placeholder={t('common.pleaseEnter')}
                onWheel={(e) => {
                  e.stopPropagation();
                }}
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
              />

              <div className="send-button-container">
                <img
                  src={process.env.PUBLIC_URL + '/images/last-section/send-icon.png'}
                  alt="send"
                  className="send-icon"
                  onClick={() => {
                    window.location.href = `mailto:cs@onefamilymall.com?subject=한스바이오텍 문의&body=${messageText}`;
                  }}
                />
              </div>
            </div>
            <div className="contact-area">
              <div className="contact-title">Contact Information</div>
              <div className="contact-info-list">
                {infoList.map((item) => (
                  <div className="info-list-item" key={JSON.stringify(item)}>
                    <img
                      src={process.env.PUBLIC_URL + item.iconSrc}
                      alt={item.iconSrc.split('/').pop()?.replace('.png', '')}
                      className="info-list-item-icon"
                    />
                    <div className="info-text">{item.text}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer
          openPrivacyPolicyModal={() => setPrivacyPolicyModalIsShow(true)}
          openTermsModal={() => setTermsModalIsShow(true)}
        />
      </div>
      {/* Mobile */}
      <div className="last-section-mobile">
        <div className="last-section-inner">
          <div className="last-section-top">
            <div className="last-section-title">CONTACT</div>
            <div className="company-info-area">
              <div className="company-info-item">
                <div className="company-info-item-title">{t('common.address')}</div>
                <div className="company-info-item-content">{t('lastSection.companyAddress')}</div>
              </div>
              {/* <div className="company-info-item">
                <div className="company-info-item-title">{t('common.customerCenter')}</div>
                <div className="company-info-item-content">82+ 02-6091-1126</div>
              </div> */}
              <div className="company-info-item">
                <div className="company-info-item-title">{t('common.emailAddress')}</div>
                <div className="company-info-item-content">cs@onefamilymall.com</div>
              </div>
            </div>
          </div>
          <div className="last-section-bottom">
            <div className="partners-title-area">
              <div className="partners-title-line"></div>
              <div className="partners-title-text">PARTNER</div>
            </div>
            <div className="partners-site-area">
              {/* <div className="partners-site-item">
                
              </div> */}
              {siteList.map((item, index) => (
                <div className="partners-site-item" key={index + JSON.stringify(item)}>
                  <img
                    className="partners-site-item-img"
                    src={process.env.PUBLIC_URL + item.imgSrc}
                    alt={item.imgSrc.split('/').pop()?.replace('.png', '')}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer
          openPrivacyPolicyModal={() => setPrivacyPolicyModalIsShow(true)}
          openTermsModal={() => setTermsModalIsShow(true)}
        />
      </div>
      <PrivacyPolicyModal
        isShow={privacyPolicyModalIsShow}
        onClickCloseButton={() => setPrivacyPolicyModalIsShow(false)}
      />
      <TermsModal isShow={termsModalIsShow} onClickCloseButton={() => setTermsModalIsShow(false)} />
    </div>
  );
}

const lastSectionCss = css`
  height: 100%;
  padding-bottom: ${sizes.footerHeight}px;
  position: relative;
  .last-section {
    height: 100%;

    .map-area {
      height: 40%;
    }
    .middle-area {
      height: 60%;
      position: relative;

      .site-list-wrap {
        display:none;
       /* display: flex;*/
        width: 1200px;
        margin: 0 auto;
        align-items: flex-end;
        height: 100%;

        .site-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .site-list-item {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
          }
        }
      }

      .send-message-container {
        position: absolute;
        top: -35%;
        display: flex;
        width: 1000px;
        box-shadow: 10px 17.3px 8px 0 rgba(15, 15, 15, 0.23);

        /* absolute align center */
        left: 50%;
        transform: translateX(-50%);
        .send-message-area {
          padding: 40px 120px 40px 55px;
          background-color: ${colors.white};
          position: relative;
          /* width: 74%; */
          flex: 1.8;
          display: flex;
          flex-direction: column;
          .send-message-title {
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 2.4rem;
            margin-bottom: 4rem;
            color: ${colors.black};
          }
          .inputs-area {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            input[type='text'] {
              width: calc(50% - 30px);
              margin-bottom: 24px;
              font-size: 1.3rem;
              padding: 0.3rem 0.5rem 0.9rem;
              border-color: #f2f2f2;
              border-bottom-width: 2px;
            }
          }
          textarea {
            width: 100%;
            background-color: #f9f9f9;
            flex: 1;
            padding: 2.1rem 1.5rem;
            font-size: 1.3rem;
          }

          .send-button-container {
            position: absolute;
            right: 3%;
            bottom: 6%;
            border-color: ${colors.white};
            border-width: 3px;
            border-radius: 100px;
            background-color: #3ac769;
            box-shadow: 1.5px 2.6px 9px 0 rgba(15, 15, 15, 0.4);
            cursor: pointer;
            width: 65px;
            height: 65px;
            padding-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            .send-icon {
              width: 37px;
              height: 37px;
            }
          }
        }

        .contact-area {
          background-color: #3ac769;
          padding: 36px;
          position: relative;
          flex: 1;
          min-height: 360px;
          .contact-title {
            font-weight: 700;
            font-size: 2rem;
            color: ${colors.white};
            margin-bottom: 7rem;
            text-align: center;
          }
          .contact-info-list {
            .info-list-item {
              display: flex;
              align-items: center;
              &:not(:last-of-type) {
                margin-bottom: 40px;
              }
              .info-list-item-icon {
                margin-right: 17px;
                width: 38px;
              }
              .info-text {
                flex: 1;
                color: ${colors.white};
                font-size: 1.7rem;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
  .mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 100%;
  }
  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 100%;
  }

  .last-section-mobile {
    display: none;
  }

  @media (max-width: ${sizes.maxWidth}px) {
    .last-section {
      display: none;
    }

    .last-section-mobile {
      display: initial;

      .last-section-inner {
        padding: 20px;
        .last-section-top {
          margin-bottom: 40px;

          .last-section-title {
            font-family: 'Poppins';
            font-size: 4rem;
            font-weight: 700;
            color: #3ac769;
            margin-bottom: 4rem;
          }
          .company-info-area {
            .company-info-item {
              &:not(:last-of-type) {
                margin-bottom: 3.4rem;
              }
              .company-info-item-title {
                font-size: 2.1rem;
                font-weight: 700;
                margin-bottom: 5px;
              }
              .company-info-item-content {
                font-size: 2.1rem;
              }
            }
          }
        }
      }

      .last-section-bottom {
        .partners-title-area {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .partners-title-line {
            background-color: #2c2c2c;
            height: 1px;
            width: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            z-index: -2;
          }
          .partners-title-text {
            font-family: 'Poppins';
            font-weight: 700;
            color: ${colors.white};
            font-size: 3rem;
            padding: 0.5rem 8rem;
            background-color: #2c2c2c;
            border-radius: 4rem;
          }
        }

        .partners-site-area {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          padding: 30px 0;
          .partners-site-item {
            width: calc(100% / 3 - 20px);
            margin: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            .partners-site-item-img {
              width: 100%;
            }

            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3) {
              transform: translateX(5px);
            }

            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
              transform: translateX(-15px);
            }

            &:nth-of-type(7),
            &:nth-of-type(8),
            &:nth-of-type(9) {
              transform: translateX(12px);
            }
          }
        }
      }
    }
  }
`;

export default LastSection;
